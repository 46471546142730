import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postAuthReq, postReq } from '../../../utils/apiHandlers';
import { helper } from '../../../helpers';
import { reactIcons } from '../../../utils/icons';
import { toast } from 'react-toastify';
import Loader from '../../../components/loaders/Loader';

const GrowthCalculaterCol = ({ fractionVal, setForm, form, handleExplore }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [returnPrice, setReturnPrice] = useState();
  // const [form, setForm] = useState({
  //   fraction: 0,
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value <= 100) {
      setForm({
        ...form,
        [name]: value,
      });
    }

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      getCurrentStage(value);
    }, 1000);
  };
  const debounceTimeout = useRef(null);
  const handleBuy = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const payload = {
        fraction: form.fraction,
      };
      const res = await postAuthReq('/purchase/node-fraction-price', payload);
      const { status } = res;
      if (status) {
        navigate('/register');
      }
    } catch (error) {
      toast.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  const getCurrentStage = async (val) => {
    const res = await postReq('/purchase/node-fraction-price');
    const { data, status } = res;
    if (status) {
      let sum = 0;
      let currentValue = val;
      for (let i = 0; i < val; i++) {
        let percentage =
          data.firstFractionBlockRewardPercent -
          (data.currentNodeFractionIndex - i) *
            data?.fractionBlockRewardDecrementConstant;
        let findValue = helper.twoYearsReward * (percentage / 100);
        sum += findValue;
        currentValue--;
      }
      setReturnPrice(sum);
    }
  };


  const handleIncrement = (name) => {
    const input = document.getElementsByName(name)[0];
    const currentValue = parseFloat(input.value) || 0;
    input.value = currentValue + 1;
  };

  const handleDecrement = (name) => {
    const input = document.getElementsByName(name)[0];
    const currentValue = parseFloat(input.value) || 0;
    if (currentValue > 0) {
      input.value = currentValue - 1;
    }
  };
  return (
    <div className="flex flex-col w-full mt-0 md:mt-6">
      <div className="mt-0 md:mt-8 flex flex-col lg:flex-row w-full gap-8 lg:gap-28">
        <div className="col-span-1 w-full mb-0 md:mb-8 lg:mb-0 mt-4 md:mt-16">
          <h3 className="text-white text-3xl lg:text-[70px] leading-[30px] md:leading-[50px] lg:leading-[70px]">
            Experience the Explosive Growth
          </h3>
          <p className="text-[#AFAFAF] text-sm md:text-[16px] lg:text-[18px] leading-[20px] md:leading-[20px] lg:leading-[25px] mt-3 md:mt-6">
            Join the MST chain revolution in i-gaming! As a validator, seize
            high rewards while prices are low. Our five-stage node sale offers
            exclusive advantages. Invest now to maximize your potential!
          </p>
        </div>

        <div className="col-span-1 w-full">
          <div className="flex justify-between items-center mb-3">
            <div>
              <h2 className="text-16 md:text-[28px] lg:text-[34px] text-white flex items-center">
                Genesis Nodes
                <span className="ml-1 text-20 pt-[6px]">{reactIcons.info}</span>
              </h2>
            </div>
            <h4 className="text-12 md:text-16 lg:text-[18px] text-white text-end md:text-end">
              200 MSTC / block validation
            </h4>
          </div>
          <div className="bg-common rounded-sm w-full relative py-3 border border-[#FFFFFF] mb-4 md:mb-0">
            <div className="w-full flex flex-col justify-center items-center pt-4">
              <div className="calculate-border-red mb-4 w-[90%] bg-[#0F0F0F] text-white rounded-lg p-1 py-3 flex justify-between items-center">
                <p className="text-sm lg:text-[12px] text-white ml-2">
                  Enter the number of a fraction 
                </p>
                {/* <input
                  type="number"
                  className="text-14 text-white bg-[#0F0F0F] w-[90px] h-[35px] md:w-[130px] md:h-[80%] py-3 rounded-md shadow-lg text-end border-b-2 border-b-[#EE0000] border-l-2 border-l-[#EE0000] lg:w-[214px] focus:border-l-[#EE0000] focus:border-b-[#EE0000]"
                  placeholder="0"
                  step="1"
                  min={0}
                  name="fraction"
                  onChange={handleChange}
                /> */}
                <div className="relative inline-block">
                  <input
                    type="number"
                    className="text-16 lg:text-18 text-white bg-[#0F0F0F] w-[150px] h-[35px] md:w-[130px] md:h-[80%] py-3 rounded-md shadow-lg text-end border-b-2 border-b-[#EE0000] border-l-2 border-l-[#EE0000] lg:w-[214px] focus:border-l-[#EE0000] focus:border-b-[#EE0000] pr-8 font-semibold"
                    placeholder="0"
                    step="1"
                    min={1}
                    value={form.fraction}
                    name="fraction"
                    onChange={handleChange}
                  />

                  <button
                    type="button"
                    onClick={() => handleIncrement('fraction')}
                    className="absolute text-12 lg:text-14 right-2 top-0 lg:top-[10px] h-[17px] w-[17px] flex items-center justify-center text-white rounded-full"
                  >
                    ▲
                  </button>

                  <button
                    type="button"
                    onClick={() => handleDecrement('fraction')}
                    className="absolute right-2 text-12 lg:text-14 bottom-[3px] lg:bottom-[10px] h-[17px] w-[17px] flex items-center justify-center text-white rounded-full"
                  >
                    ▼
                  </button>
                </div>
              </div>

              <div className="calculate-border-gray mb-4 w-[90%] bg-[#0F0F0F] text-white rounded-lg p-1 py-[5px] flex justify-between items-center">
                <p className="text-sm lg:text-[12px] text-white ml-2">Price</p>
                <div className="relative flex items-center">
                  <input
                    type="number"
                    className="text-14 lg:text-16 font-semibold text-white bg-[#0F0F0F] w-[140px] h-[30px] md:w-[150px] md:h-[80%] rounded-md shadow-lg text-end lg:w-[210px]"
                    placeholder="1234"
                    step="1"
                    value={fractionVal && fractionVal?.toFixed(2)}
                  />
                  <span className="absolute left-2 text-white font-semibold">
                    INR
                  </span>
                </div>
              </div>

              {/* <div className="calculate-border-gray mb-4 w-[90%] bg-[#0F0F0F] text-white rounded-lg p-1 py-[5px] flex justify-between items-center">
                <p className="text-sm lg:text-[12px] text-white ml-2">
                  Returns in next 2 years
                </p>
                <div className="relative flex items-center">
                  <input
                    type="number"
                    className="pl-10 text-16 font-semibold text-white bg-[#0F0F0F] w-[80px] h-[30px] md:w-[150px] lg:w-[210px] md:h-[80%] rounded-md shadow-lg text-end"
                    placeholder="0"
                    step="1"
                    value={returnPrice && returnPrice?.toFixed(2)}
                  />
                  <span className="absolute left-2 text-white font-semibold">
                    INR
                  </span>
                </div>
              </div> */}

              <div className="flex flex-col md:flex-row justify-between items-center w-full px-6 md:px-[35px] mt-4 gap-4">
                <div className="bg-white rounded-md p-3 px-4 font-semibold w-full md:w-auto">
                  <button
                    type="button"
                    className="text-[#0F0F0F] w-full text-12 md:text-16"
                    onClick={handleExplore}
                  >
                    Explore the Calculation
                  </button>
                </div>
                <div
                  className="bg-[#EE0000] p-3 px-3 md:px-[40px] rounded-md border-b-2 border-b-white border-r-2 border-r-white w-full md:w-auto"
                  onClick={handleBuy}
                >
                  <button
                    type="button"
                    className="text-12 md:text-16 text-white font-semibold w-full"
                  >
                    {isLoading ? <Loader /> : 'Register Now'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrowthCalculaterCol;
