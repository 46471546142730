import React, { useEffect, useState } from 'react';
import { postAuthReq } from '../../utils/apiHandlers';
import { isYupError, parseYupError } from '../../utils/Yup';
import {
  kycValidation,
  otpValidation,
  panCardValidation,
} from '../../utils/validation';
import { toast } from 'react-toastify';
import Loader from '../../components/loaders/Loader';
import Aadharcard from './AadharCard';
import PanCard from './PanCard';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../../redux/Slice/User/user';
import KycCompleted from './KycCompleted';
import { useLocation } from 'react-router-dom';
const Kyc = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [captchaImg, setCaptchaImg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [isRotating, setIsRotating] = useState(false);
  const [form, setForm] = useState({
    aadharNumber: '',
    captcha: '',
    otp: '',
    panNumber: '',
  });

  const [formError, setFormError] = useState({
    aadharNumber: '',
    captcha: '',
    otp: '',
    panNumber: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: '',
    });
  };

  const generateCaptcha = async () => {
    setIsRotating(true);
    const res = await postAuthReq('/kyc/generate-captcha');

    const { status, data } = res;
    if (status) {
      setIsRotating(false);
      setCaptchaImg(data?.data);
    }
  };
  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleSentOtp = async () => {
    if (otpLoading) return;
    try {
      setOtpLoading(true);
      const payload = {
        aadharNumber: form.aadharNumber,
        captcha: form.captcha,
      };
      await kycValidation.validate(form, {
        abortEarly: false,
      });
      const res = await postAuthReq('/kyc/generate-aadhar-otp', payload);
      const { status, data } = res;
      if (status) {
        setOtpSent(true);
        toast.success(data?.message);
      } else {
        toast.error('Please Enter Correct Captcha');
        // toast.error(res?.error?.message);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    } finally {
      setOtpLoading(false);
    }
  };

  const handleVerifyOtp = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const payload = {
        otp: form.otp,
      };
      await otpValidation.validate(form, {
        abortEarly: false,
      });
      const res = await postAuthReq('/kyc/verify-aadhar-otp', payload);
      const { status, data } = res;
      if (status) {
        setForm({
          otp: '',
        });
        toast.success(data?.status);
        dispatch(fetchUserData());
      } else {
        toast.error(res?.error?.message);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleVerifyPanCard = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      const payload = {
        panNumber: form.panNumber,
      };
      await panCardValidation.validate(form, {
        abortEarly: false,
      });
      const res = await postAuthReq('/kyc/pan-varification', payload);
      const { status, data } = res;
      if (status) {
        dispatch(fetchUserData());
        toast.success(data?.status);
        setForm({
          panNumber: '',
        });
      } else {
        toast.error(res?.error?.message);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setOtpSent(false);
    setForm({
      aadharNumber: '',
      captcha: '',
      otp: '',
      panNumber: '',
    });

    setFormError({});
  };

  const getToken = Cookies.get('accessToken');
  const { userData } = useSelector((state) => state?.user || {});
  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);
  const isVerify = userData && userData?.isVerified;
  const isAadharVerify = userData && userData?.meta?.isAadhaarVerified;
  return (
    <>
      {!isVerify && (
        <div className="h-[100%]">
          <div className="flex items-center justify-between mb-4 md:mb-10">
            <h4 className="text-white text-start md:text-center text-16 md:text-[24px] lg:text-[24px]">
              KYC
            </h4>
            <button
              type="button"
              className={`${
                isVerify
                  ? 'bg-[#83d283] border-green-500 text-black'
                  : 'bg-[#FFA6A6] border-[#FFCACA] text-white'
              } py-2.5 px-6 rounded-lg flex items-center justify-center gap-2 border relative pr-[20px]  md:pr-[50px] text-10 md:text-12 font-normal`}
            >
              KYC {isVerify ? 'Verified' : 'Pending'}
            </button>
          </div>
          <div className="max-w-[740px] text-white">
            <ul className="flex items-center justify-around">
              <li className="mb-1 block text-12 font-semibold">Step 1</li>
              <li className="mb-1 block text-12 font-semibold">Step 2</li>
            </ul>
            <div className="pb-[50px] relative">
              <div
                className={`flex items-center justify-around overflow-hidden rounded-full border border-[#FF0000] ${
                  isAadharVerify ? 'animate-fill border border-none' : ''
                }`}
              >
                <div
                  className={`cursor-default text-14 md:text-20 w-1/2 text-center py-2.5 rounded-full ${
                    !isAadharVerify ? 'animate-retract' : ''
                  }`}
                >
                  Aadhaar Card
                </div>
                <div
                  className={`cursor-default text-14 md:text-20 w-1/2 text-center py-2.5 rounded-full ${
                    isAadharVerify ? '' : ''
                  }`}
                >
                  PAN Card
                </div>
                <div
                  className={`absolute ${
                    !isAadharVerify
                      ? 'left-[320px] top-[55px]'
                      : 'right-0 top-[55px]'
                  }`}
                >
                  {/* <p className="text-12 text-white">Processing</p> */}
                </div>
              </div>
            </div>
            <div className="p-3 border border-[#FF0000] rounded transition ease-in-out delay-150">
              <label className="mb-1 block text-10 md:text-12 font-semibold">
                Two Step Verification
              </label>
              <div className="flex items-center gap-3 mb-5">
                <button
                  type="submit"
                  className={`rounded py-2.5 px-6 text-10 md:text-12 min-w-[120px] font-semibold cursor-default ${
                    !isAadharVerify
                      ? 'bg-gradient-red text-white border border-white'
                      : 'text-[#000000CC] border bg-white border-[#FF0000]'
                  }`}
                >
                  Aadhaar Card
                </button>
                <button
                  type="submit"
                  className={`rounded py-2.5 px-6 text-12 min-w-[120px] font-semibold cursor-default ${
                    isAadharVerify
                      ? 'bg-gradient-red text-white border border-white'
                      : 'text-[#000000CC] border bg-white border-[#FF0000]'
                  }`}
                >
                  PAN Card
                </button>
              </div>
              <div>
                {!isAadharVerify && (
                  <Aadharcard
                    form={form}
                    formError={formError}
                    handleChange={handleChange}
                    handleSentOtp={handleSentOtp}
                    otpLoading={otpLoading}
                    optSent={otpSent}
                    generateCaptcha={generateCaptcha}
                    isRotating={isRotating}
                    captchaImg={captchaImg}
                    isVerify={isVerify}
                    getToken={getToken}
                  />
                )}

                {isAadharVerify && (
                  <PanCard
                    form={form}
                    formError={formError}
                    handleChange={handleChange}
                  />
                )}

                <div className="flex justify-end items-center gap-2 mt-8">
                  {!isAadharVerify && (
                    <button
                      type="button"
                      onClick={handleReset}
                      className="text-white rounded-md font-normal py-2.5 px-8 bg-[#000000] border border-[#EE0000] md:text-16 text-14 w-1/2 md:w-[140px]"
                      disabled={isVerify && getToken}
                    >
                      Reset
                    </button>
                  )}
                  {!otpSent && (
                    <button
                      type="button"
                      onClick={handleSentOtp}
                      // onClick={handleVerifyOtp}
                      className="text-white py-2.5 px-7 bg-gradient-red rounded-md font-normal border border-[#EE0000] w-1/2 md:w-[145px]"
                    >
                      {otpLoading ? <Loader /> : 'Send OTP'}
                    </button>
                  )}

                  {otpSent && !isAadharVerify && (
                    <button
                      type="button"
                      // onClick={handleSentOtp}
                      onClick={handleVerifyOtp}
                      className="text-white py-2.5 px-8 bg-gradient-red rounded-md font-normal border border-[#EE0000] w-1/2 md:w-[145px]"
                    >
                      {isLoading ? <Loader /> : 'Submit'}
                    </button>
                  )}
                  {}
                  {isAadharVerify && (
                    <button
                      type="button"
                      onClick={handleVerifyPanCard}
                      className="text-white py-2.5 px-8 bg-gradient-red rounded-md font-normal border border-[#EE0000] w-1/2 md:w-[180px]"
                      disabled={isVerify && getToken}
                    >
                      {isLoading ? <Loader /> : 'Submit'}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isVerify && <KycCompleted setOpen={setOpen} open={open} />}
    </>
  );
};
export default Kyc;
