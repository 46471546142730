import React, { useState } from "react";
import { reactIcons } from "../../utils/icons";
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import ComingSoon from "../../components/Forms/ComingSoon";
const cardData = [
  { title: "Lifetime Reward Coin", num: 4000 },
  { title: "Current Balance", num: 400000 },
  { title: "Withdrawal Balance", num: 4000, icon: reactIcons.info },
];
const str = "0x76a22F98e81Df200FD4098F305b66Bf284c45E4F";
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "white", // Tooltip text color
    background:
      "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
    fontSize: "16px",
    padding: "16px",
  },
});
const Withdraw = () => {
  const [form, setForm] = useState({
    amount: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };
  const toolTipTitle =
    "There should be an information icon on the card that displays- You are only allowed to withdrawal 80% of your overall earnings";
  return (
    <>
      <ComingSoon />
      <div className="h-[100%]">
        <div className="flex items-center justify-between mb-3 md:mb-10">
          <h4 className="text-white text-start md:text-center text-16 md:text-[24px] lg:text-[24px]">
            Withdraw
          </h4>
        </div>

        <div className="flex flex-wrap items-center gap-8 md:gap-12 lg:gap-[80px] mb-8">
          {cardData.map((item, idx) => (
            <div
              key={idx}
              className="relative w-full md:w-1/2 lg:w-1/3 max-w-sm rounded shadow-lg bg-gradient-1 p-[28px]"
            >
              <CustomTooltip title={toolTipTitle}>
                <div className="cursor-pointer absolute top-[10px] text-white left-[330px] text-[20px] md:text-[25px] lg:text-[30px] pl-2">
                  {item.icon}
                </div>
              </CustomTooltip>
              <h3 className="font-semibold text-[24px] md:text-[30px] lg:text-[40px] mb-2 text-white">
                {item.title}
              </h3>
              <p className="text-white text-end text-[18px] md:text-[22px] lg:text-[25px] font-semibold cursor-pointer">
                {item.num}
              </p>
            </div>
          ))}
        </div>
        <div className="text-white mt-3">
          <label className="mb-2 block text-10 md:text-14">
            Your Connected Wallet Address
          </label>
          <div className="border border-[#BEBEBE] flex items-center justify-between bg-[#ffffff1f] p-2.5 rounded-lg max-w-[550px]">
            <div>{str.slice(0, 6) + "..." + str.slice(20, 22)}</div>
            <button type="button">{reactIcons.copy}</button>
          </div>
        </div>
        <div className="input_field w-[100%] max-w-[550px] mt-4">
          <label className="mb-2 block text-10 md:text-14 text-white">
            Enter Amount to Withdraw
          </label>
          <input
            type="text"
            placeholder="Enter Withdraw Amount"
            name="amount"
            onChange={handleChange}
            className="text-input bg-[#786e6e4d] text-white border border-[#BEBEBE] text-14"
          />
          <div className="text-end mt-3">
            <button
              type="button"
              className="text-white rounded-md font-semibold py-2.5 px-8 bg-[#EE0000] border border-white ml-4 md:text-16 text-12 min-w-[140px]"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Withdraw;
