import React, { useEffect, useState } from 'react';
import { isYupError, parseYupError } from '../../utils/Yup';
import {
  addBuyersValidation,
  forgotPasswordValidation,
} from '../../utils/validation';
import { toast } from 'react-toastify';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { postReq } from '../../utils/apiHandlers';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Cookies from 'js-cookie';
import Loader from '../../components/loaders/Loader';

import {
  CitySelect,
  CountrySelect,
  StateSelect,
} from 'react-country-state-city';
import 'react-country-state-city/dist/react-country-state-city.css';
const RegisterPage = () => {
  const navigate = useNavigate();
  const [country, setCountry] = useState({
    id: 0,
    dialCode: 0,
    name: '',
  });
  const [stateid, setstateid] = useState({ id: 0, name: '' });
  const [focusedInput, setFocusedInput] = useState(null);
  const [time, setTime] = useState(10);
  const [city, setCity] = useState('');
  const [eye, setEye] = useState(false);
  const [sentOtp, setSentOtp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    email: '',
    address_line_1: '',
    address_line_2: '',
    mobile: '',
    password: '',
    country: '',
    mobileVerificationCode: '000000',
  });

  const [formError, setFormError] = useState({
    firstname: '',
    lastname: '',
    email: '',
    address_line_1: '',
    mobile: '',
    password: '',
    country: '',
    mobileVerificationCode: '',
  });
  const handleFocus = (name) => {
    setFocusedInput(name);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };
  const handleChange = (e) => {
    if (e && e.id) {
      setCountry({
        id: e.id,
        dialCode: e.phone_code,
        name: e.name,
      });
      setForm((prevForm) => ({
        ...prevForm,
        country: e.name,
      }));

      setFormError((prevError) => ({
        ...prevError,
        country: '',
      }));
    } else {
      const { name, value } = e.target;
      setForm({
        ...form,
        [name]: value,
      });
      setFormError({
        ...formError,
        [name]: '',
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    try {
      setIsLoading(true);
      await addBuyersValidation.validate(form, {
        abortEarly: false,
      });
      const payload = {
        ...form,
        dialCode: country.dialCode,
        mobile: '+' + country.dialCode + form.mobile,
        state: stateid.name,
        city: city,
      };
      const res = await postReq('/auth/register', payload);
      const { status, data } = res;
      if (status) {
        Cookies.set('accessToken', data.accessToken);
        navigate('/kyc');
        toast.success('Register Successfully');
      } else {
        toast.error(res?.error?.message);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleSentOtp = async () => {
    if (otpLoading) return;
    try {
      setOtpLoading(true);
      const payload = {
        mobile: '+' + country.dialCode + form.mobile,
        type: 'register',
        country: country.name,
      };
      await forgotPasswordValidation.validate(form, {
        abortEarly: false,
      });
      const res = await postReq('/auth/send-code', payload);
      const { status, data } = res;
      if (status) {
        const duration = data?.mobile?.timeout || 120000;
        setTime(duration / 1000);
        setSentOtp(true);
        toast.success('OTP Sent Successfully');
      } else {
        if (Array.isArray(res?.error?.message)) {
          toast.error(res?.error?.message[0]);
        } else {
          toast.error(res?.error?.message || 'An unknown error occurred');
        }
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    } finally {
      setOtpLoading(false);
    }
  };
  useEffect(() => {
    if (sentOtp && time > 0) {
      const timerId = setInterval(() => {
        setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [sentOtp]);

  useEffect(() => {
    if (time === 0 && sentOtp) {
      setSentOtp(false);
      toast.error('OTP has expired!');
    }
  }, [time, sentOtp]);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return (
    <div className="flex justify-center items-start lg:pt-4 bg-[#0F0F0F] overflow-auto relative h-[100dvh]">
      <div className="text-white px-5 pt-4 lg:mb-5 w-full max-w-[900px] z-[9999]">
        <div className="pt-2 lg:pt-0 pb-3">
          <h4 className="text-white text-18 md:text-[28px] lg:text-[36px]">
            Become a Key Player in the{' '}
            <span className="text-[#EE0000] text-18 md:text-[40px] lg:text-[46px]">
              MST Blockchain{' '}
            </span>{' '}
            {/* <br /> */}
            Fill Out The form below to secure your Node today!
          </h4>
        </div>
        <div className="relative">
          <form onSubmit={handleSubmit} className="">
            <div className="lg:grid grid-cols-1 sm:grid-cols-2 gap-6 mt-4">
              <div className="mb-3 lg:mb-0">
                <label className="mb-2 block">First Name</label>
                <input
                  type="text"
                  placeholder="Enter First Name"
                  autoComplete="off"
                  name="firstname"
                  onKeyDown={(e) => {
                    if (/[^a-zA-Z ]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onFocus={() => handleFocus('firstname')}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  //  className={`text-input bg-[#786e6e4d] text-white border text-14 ${
                  //    focusedInput === 'firstname'
                  //      ? 'border-red-500'
                  //      : 'border-[#BEBEBE]'
                  //  }`}
                  className={`text-input bg-[#786e6e4d] text-white text-12 lg:text-14 border ${
                    focusedInput === 'firstname'
                      ? '!border-[#EE0000]'
                      : 'border-[#BEBEBE]'
                  }`}
                />
                {formError.firstname && (
                  <div className="form-eror">{formError.firstname}</div>
                )}
              </div>
              <div className="mb-3 lg:mb-0">
                <label className="mb-2 block">Last Name</label>
                <input
                  type="text"
                  placeholder="Enter Last Name"
                  name="lastname"
                  onKeyDown={(e) => {
                    if (/[^a-zA-Z ]/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onFocus={() => handleFocus('lastname')}
                  autoComplete="off"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  className={`text-input bg-[#786e6e4d] text-white border-[#BEBEBE] text-12 lg:text-14 border ${
                    focusedInput === 'lastname'
                      ? '!border-[#EE0000]'
                      : 'border-[#BEBEBE]'
                  }`}
                />
                {formError.lastname && (
                  <div className="form-eror">{formError.lastname}</div>
                )}
              </div>
              <div className="input_field mb-3 lg:mb-0">
                <label className="mb-2 block">Email Address</label>
                <input
                  type="text"
                  placeholder="Enter Email"
                  name="email"
                  onChange={handleChange}
                  onFocus={() => handleFocus('email')}
                  onBlur={handleBlur}
                  autoComplete="off"
                  className={`text-input bg-[#786e6e4d] text-white border border-[#BEBEBE] text-12 lg:text-14 ${
                    focusedInput === 'email'
                      ? '!border-[#EE0000]'
                      : 'border-[#BEBEBE]'
                  }`}
                />
                {formError.email && (
                  <div className="form-eror">{formError.email}</div>
                )}
              </div>
              <div className="mb-3 lg:mb-0">
                <div className="input_field relative">
                  <label className="mb-2 block">Password</label>
                  <div className="flex items-center">
                    <input
                      type={eye ? 'text' : 'password'}
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                      autoComplete="off"
                      onFocus={() => handleFocus('password')}
                      onBlur={handleBlur}
                      className={`text-input bg-[#786e6e4d] text-white border border-[#BEBEBE] flex-1 pr-12 text-12 lg:text-14 ${
                        focusedInput === 'password'
                          ? '!border-[#EE0000]'
                          : 'border-[#BEBEBE]'
                      }`}
                    />
                    <span
                      className="absolute right-4 top-[68%] transform -translate-y-1/2 cursor-pointer z-0 no-focus-ring"
                      onClick={() => setEye(!eye)}
                    >
                      {eye ? (
                        <AiFillEye className="text-2xl text-theme-text" />
                      ) : (
                        <AiFillEyeInvisible className="text-2xl text-theme-text" />
                      )}
                    </span>
                  </div>
                </div>
                {formError.password && (
                  <div className="form-eror">{formError.password}</div>
                )}
              </div>
              <div className="input_field mb-3 lg:mb-0">
                <label className="mb-2 block">Country</label>
                <CountrySelect
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeHolder="Select Country"
                />
                {formError.country && (
                  <div className="form-eror">{formError.country}</div>
                )}
              </div>

              <div className="input_field mb-3 lg:mb-0">
                <label className="mb-2 block">State</label>
                <StateSelect
                  countryid={country.id}
                  onChange={(e) => {
                    setstateid({ id: e.id, name: e.name });
                  }}
                  placeHolder="Select State"
                  disabled={!country.id}
                />
              </div>
              <div className="input_field mb-3 lg:mb-0">
                <label className="mb-2 block">City</label>
                <CitySelect
                  countryid={country.id}
                  stateid={stateid.id}
                  disabled={!stateid.id}
                  onChange={(e) => {
                    setCity(e.name);
                  }}
                  placeHolder="Select City"
                />
              </div>
              <div className="input_field mb-3 lg:mb-0">
                <label className="mb-2 block">Address Line 1</label>
                <input
                  type="text"
                  placeholder="House No., Street No...."
                  name="address_line_1"
                  autoComplete="off"
                  onChange={handleChange}
                  onFocus={() => handleFocus('address_line_1')}
                  onBlur={handleBlur}
                  className={`text-input bg-[#786e6e4d] text-white border border-[#BEBEBE] text-12 lg:text-14 ${
                    focusedInput === 'address_line_1'
                      ? '!border-[#EE0000]'
                      : 'border-[#BEBEBE]'
                  }`}
                />
                {formError.address_line_1 && (
                  <div className="form-eror">{formError.address_line_1}</div>
                )}
              </div>
              <div className="input_field mb-3 lg:mb-0">
                <label className="mb-2 block">Address Line 2</label>
                <input
                  type="text"
                  placeholder="Landmark , Other Details....."
                  name="address_line_2"
                  autoComplete="off"
                  onChange={handleChange}
                  onFocus={() => handleFocus('address_line_2')}
                  onBlur={handleBlur}
                  className={`text-input bg-[#786e6e4d] text-white border border-[#BEBEBE] text-12 lg:text-14 ${
                    focusedInput === 'address_line_2'
                      ? '!border-[#EE0000]'
                      : 'border-[#BEBEBE]'
                  }`}
                />
                {/* {formError.address && (
                  <div className="form-eror">{formError.address}</div>
                )} */}
              </div>
              <div className="input_field mb-3 lg:mb-0">
                <label className="mb-2 block">Mobile Number</label>
                <div className="relative">
                  <span className="cursor-pointer absolute left-[1px] top-[2px] py-[16.4px] lg:py-[11.4px] px-[6px]  border border-r-black border-y-0 border-l-0">
                    +{country.dialCode}
                  </span>
                  <input
                    type="text"
                    placeholder="Enter Mobile Number"
                    name="mobile"
                    onChange={handleChange}
                    onFocus={() => handleFocus('mobile')}
                    onBlur={handleBlur}
                    autoComplete="off"
                    className={`text-input bg-[#786e6e4d] text-white border border-[#BEBEBE] text-12 lg:text-14 pl-[60px] ${
                      focusedInput === 'mobile'
                        ? '!border-[#EE0000]'
                        : 'border-[#BEBEBE]'
                    }`}
                  />
                  {/* {!sentOtp && (
                    <button
                      type="button"
                      className="text-white rounded flex items-center justify-center gap-2 font-normal py-2.5 px-4 bg-gradient-red border border-white min-w-[100px] lg:min-w-[120px] absolute right-0 bottom-0 top-0 m-[7px] text-[10px] lg:text-14"
                      onClick={handleSentOtp}
                    >
                      {otpLoading ? (
                        <Loader />
                      ) : !sentOtp && time ? (
                        <>
                          Send OTP
                          <img
                            src="/images/icon/send.png"
                            alt="send"
                            className="w-[12px]"
                          />
                        </>
                      ) : (
                        <>
                          Resend OTP
                          <img
                            src="/images/icon/send.png"
                            alt="send"
                            className="w-[12px]"
                          />
                        </>
                      )}
                    </button>
                  )} */}

                  {/* {sentOtp && (
                    <button
                      type="button"
                      className={`text-white rounded flex items-center justify-center gap-2 font-normal py-2.5 px-4 text-14 min-w-[120px] absolute right-0 bottom-0 top-0 m-[7px] ${
                        time
                          ? 'bg-gray-600'
                          : 'bg-gradient-red border border-white'
                      }`}
                    >
                      <>
                        <span className="font-semibold text-15 mt-1 text-red-600">
                          {' '}
                          {'0' + minutes + ':' + seconds}
                        </span>
                      </>
                    </button>
                  )} */}
                </div>
                {/* {sentOtp && (
                  <div className="text-end">
                    <span className="font-semibold text-15 mt-2 text-red-600">
                      Get a new code in {'0' + minutes + ':' + seconds}
                    </span>
                  </div>
                )} */}
                {formError.mobile && (
                  <div className="form-eror">{formError.mobile}</div>
                )}
              </div>
              {/* {sentOtp && (
                <div className="input_field mb-3 lg:mb-0">
                  <label className="mb-2 block">
                    Enter Mobile Verification Code
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Mobile Verification Code"
                    name="mobileVerificationCode"
                    onFocus={() => handleFocus('mobileVerificationCode')}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    className={`text-input bg-[#786e6e4d] text-white border border-[#BEBEBE] text-14 ${
                      focusedInput === 'mobileVerificationCode'
                        ? '!border-[#EE0000]'
                        : 'border-[#BEBEBE]'
                    }`}
                  />
                  {formError.mobileVerificationCode && (
                    <div className="form-eror">
                      {formError.mobileVerificationCode}
                    </div>
                  )}
                </div>
              )} */}
              <div className="col-span-2 flex flex-col lg:flex-row justify-between items-center mt-4 lg:mt-0">
                <div className="hidden lg:flex">
                  <p
                    className="text-[#EE0000] font-semibold cursor-pointer text-16"
                    onClick={() => navigate('/login')}
                  >
                    Already have an account? Click here to Login
                  </p>
                </div>
                <div className="flex space-x-6 mb-0 lg:mb-0">
                  <button
                    type="button"
                    onClick={() => navigate('/')}
                    className="text-black py-3 px-4 bg-white rounded-md font-semibold border border-[#EE0000] text-12 md:text-16"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="text-white rounded-md font-semibold py-3 px-6 bg-[#EE0000] text-12 md:text-16"
                  >
                    {isLoading ? <Loader /> : 'Register'}
                  </button>
                </div>

                <div className="flex lg:hidden mt-4">
                  <p
                    className="text-[#EE0000] font-semibold cursor-pointer text-12 text-center mb-3"
                    onClick={() => navigate('/login')}
                  >
                    Already have an account? Click here to Login
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="fixed right-[8px] lg:right-[0px] bottom-0">
        <div className="fixed right-[8px] lg:right-[0px] bottom-0">
          <img src="/images/wave.png" alt="Loading" />
        </div>

        <div className="fixed right-[50px] bottom-[40px]">
          <img
            src="/images/outlit/bigbox.png"
            alt="Wave background"
            className="w-[85px] opacity-[0.2] lg:opacity-100"
          />
        </div>
        <div className="fixed right-[165px] bottom-[112px]">
          <img
            src="/images/outlit/smallbox.png"
            alt="Wave background"
            className="w-[50px] opacity-[0.2] lg:opacity-100"
          />
        </div>
      </div>
    </div>
  );
};
export default RegisterPage;
