import React from 'react';
import { useNavigate } from 'react-router-dom';
import { reactIcons } from '../../utils/icons';
import Cookies from 'js-cookie';

const Header = ({ toggleSidebar, setIsOpen, isOpen, isVerify }) => {
  const navigate = useNavigate();
  return (
    <div className="h-[80px] w-full z-[9999] bg-black border-b border-[#FFE3E3]">
      <div className="flex lg:items-center justify-between gap-3 cursor-pointer px-3 py-3">
        <div
          onClick={() => {
            const cookeiData = Cookies.get(`accessToken`);
            if (cookeiData && !isVerify) {
              navigate('/kyc');
            } else if (cookeiData) {
              navigate('/dashboard');
            } else {
              navigate('/');
            }
          }}
        >
          <img src="./images/logo/logo.png" alt="logo" className="w-[180px]" />
        </div>
        <div className="pr-1 sm:flex lg:hidden">
          <button
            onClick={toggleSidebar}
            className="w-10 h-10 flex-center text-white bg-common rounded-full text-3xl"
          >
            {reactIcons.toggle}
          </button>
        </div>
      </div>
    </div>
  );
};
export default Header;
