import * as yup from 'yup';

export const signUpValidation = yup.object().shape({
  email: yup
    .string()
    .required('Please enter you email address')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter valid email',
    ),
});

export const subAdminValidation = yup.object().shape({
  fullname: yup.string().required('Please enter you full name'),
  email: yup
    .string()
    .required('Please enter you email address')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter valid email',
    ),
  password: yup
    .string()
    .required('Please enter password.')
    .matches(
      /^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/,
      'Only accept One Uppercase and atleast one special characters and numbers.',
    )
    .min(8, 'Minimum 8 characters is required.'),
});
export const addBuyersValidation = yup.object().shape({
  firstname: yup
    .string()
    .required('Please enter your first name')
    .max(50, 'First Name length invalid'),
  lastname: yup
    .string()
    .required('Please enter your last name')
    .max(50, 'Last Name length invalid'),
  address_line_1: yup.string().required('Please enter your address'),
  // address2: yup.string().required('Please enter your address'),
  country: yup.string().required('Please select country'),
  email: yup
    .string()
    .required('Please enter you email address')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter valid email',
    )
    .max(100, 'Email exceeds limit'),

  mobile: yup.string().required('Mobile number is required'),
  password: yup
    .string()
    .required('Please enter password.')
    .min(8, 'Minimum 8 characters is required.')
    .max(20, 'Maximum 20 characters is required.'),
  // mobileVerificationCode: yup
  //   .string()
  //   .required('Please Enter Mobile Verification Code'),
  // password: yup
  //   .string()
  //   .required('Please enter password.')
  //   .matches(
  //     /^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/,
  //     'Only accept One Uppercase and atleast one special characters and numbers.',
  //   )
  //   .min(8, 'Minimum 8 characters is required.'),
});

export const growthCal = yup.object().shape({
  fraction: yup.string().required('Fraction number is required'),
});
export const currencyValidation = yup.object().shape({
  currency: yup.string().required('Required'),
  currentRate: yup.string().required('Required'),
  agentCommission: yup
    .number()
    .positive()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Required'),
  enhancer: yup
    .number()
    .positive()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Required'),
  platformFee: yup
    .number()
    .positive()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required('Required'),
});
export const userValidation = yup.object({
  fullname: yup.string().required('Full name is required'),
  email: yup
    .string()
    .required('Email is required')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter valid email',
    ),
  mobile: yup
    .string()
    .required('Mobile number is required')
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      'Please enter only 10 digit number.',
    ),
  city: yup.string().nullable(),
  country: yup.string().nullable(),
  whatsapp: yup
    .string()
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      'Please enter only 10 digit number.',
    )
    .nullable(),
  telegram: yup.string().nullable(),
});
export const partnerValidation = yup.object({
  fullname: yup.string().required('Full name is required'),
  email: yup
    .string()
    .required('Email is required')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter valid email',
    ),
  mobile: yup
    .string()
    .required('Mobile number is required')
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      'Please enter only 10 digit number.',
    ),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  timeZone: yup.string().required('TimeZone is required'),
  whatsapp: yup
    .string()
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      'Please enter only 10 digit number.',
    )
    .nullable(),
  telegram: yup.string().required('Telegram is required'),
});
export const QrCodeValidation = yup.object().shape({
  upi: yup.string().required('Required'),
  image: yup.string().required('Required'),
});

export const AssignPartnerValidation = yup.object().shape({
  partnerId: yup.string().required('Required'),
});

export const AccountValidation = yup.object().shape({
  accountHolderName: yup.string().required('Required'),
  accountNumber: yup
    .string()
    .matches(/^[0-9]{9,18}$/, 'Invalid account number')
    .required('Required'),
  bankName: yup.string().required('Required'),
  ifscCode: yup
    .string()
    .matches(/^[A-Za-z]{4}[0-9]{7}$/, 'Invalid IFSC code')
    .required('Required'),
});

export const merchantValidationSchema = yup.object().shape({
  fullname: yup.string().required('Full name is required'),
  email: yup
    .string()
    .required('Email is required')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Please enter valid email',
    ),
  websiteDomain: yup.string().required('Website Domain is required'),
  platformName: yup.string().required('Platform Name is required'),
  callbackUrl: yup.string().required('Call Back Url is required'),
  uin: yup.string().required('uin is required'),
  walletAddress: yup.string().required('Wallet Address is required'),
  howToFind: yup.string().required('How to find is required'),
});
export const loginValidation = yup.object().shape({
  emailOrMobile: yup.string().required('Mobile number is required'),

  password: yup.string().required('Please enter password.'),
});

export const forgotPasswordValidation = yup.object().shape({
  mobile: yup.string().required('Mobile number is required'),

  // otp: yup.string().required('Please enter otp.'),
  // password: yup.string().required('Please enter password.'),
});
export const resetPassword = yup.object().shape({
  mobile: yup.string().required('Mobile number is required'),
  otp: yup.string().required('Please enter otp.'),
  password: yup.string().required('Please enter password.'),
});
export const kycValidation = yup.object().shape({
  aadharNumber: yup
    .string()
    .required('Aadhar number is required')
    .matches(
      /^\d{12}$/,
      'Aadhar number must be exactly 12 digits long and contain only numbers',
    ),
  captcha: yup.string().required('Please Enter Captcha'),
  // otp: yup.string().required('Please Enter OTP'),
});
export const panCardValidation = yup.object().shape({
  panNumber: yup.string().required('Please Enter Pan Number'),
});
export const otpValidation = yup.object().shape({
  otp: yup.string().required('Please Enter otp'),
});
export const mobileVerificationValidation = yup.object().shape({
  mobile_verification_code: yup
    .string()
    .required('Please Enter Mobile Verification Code'),
});
export const walletAddress = yup.object().shape({
  walletAddress: yup.string().required('Please Enter Wallet Address'),
});
export const nodesPurchaseValidation = yup.object().shape({
  numberOfFractionToBuy: yup
    .number()
    .typeError('Please Enter No of Fraction To Buy')
    .required('Please Enter No of Fraction To Buy')
    .min(1, 'The number must be greater than zero'),
});
