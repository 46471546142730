import React, { useState } from "react";
import { motion } from "framer-motion";
import CubeAnimation from "../../components/animations/CubeAnimation";
import ComingSoon from "../../components/Forms/ComingSoon";
const DashboardLanding = () => {
  const [graphAnimationComplete, setGraphAnimationComplete] = useState(false);
  const [withrawAnimationComplete, setWithdrawAnimationComplete] =
    useState(false);
  return (
    <>
      <ComingSoon />
      <div className="flex flex-col gap-2 md:gap-5">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-2 md:gap-5">
          <div className="col-span-3 bg-gradient-2 overflow-hidden shadow-[rgba(0,0,0,0.35)_0px_5px_15px] p-2 bg-[#121212] min-h-[240px] rounded-md">
            <div className="flex gap-5 justify-around h-full">
              <div className="w-[173px] xl:w-[204px] relative">
                <motion.img
                  src="/images/dashboard/laptop/laptop.png"
                  alt=""
                  className="absolute top-0 z-10"
                  initial={{ y: "-100%", opacity: 0 }} // Starts off-screen from the top
                  animate={{ y: 0, opacity: 1 }} // Animates to its normal position
                  transition={{ duration: 1.5, ease: "easeOut" }} // Adjust the duration as needed
                />
                <motion.img
                  src="/images/dashboard/laptop/object.png"
                  alt=""
                  className="absolute bottom-[-85px] md:bottom-[-92px] lg:bottom-[-95px] xl:bottom-[-138px]"
                  initial={{ y: "100%", opacity: 0 }} // Starts off-screen from the bottom
                  animate={{ y: 0, opacity: 1 }} // Animates to its normal position
                  transition={{ duration: 1.5, ease: "easeOut", delay: 0.2 }} // Adjust the duration and add delay for sequential effect
                />
              </div>

              <motion.img
                src="/images/dashboard/approve.png"
                alt=""
                className="w-[100px] h-[100px] hidden md:block"
                initial={{ y: "-100%", opacity: 0 }} // Starts off-screen from the top
                animate={{ y: 0, opacity: 1 }} // Animates to its normal position
                transition={{ duration: 1.5, ease: "easeOut" }}
              />
              <div className="w-[150px] relative">
                <div className="absolute bottom-0 w-[150px] h-[150px]">
                  {/* <div className="relative w-[150px] h-[150px]">
                  <motion.img
                    src="/images/dashboard/block.png"
                    alt=""
                    className="absolute z-20"
                    initial={{ top: 0, left: "-518px", x: -30 }}
                    animate={{
                      top: [-518, 0, 0, -116, -116, 75],
                      left: [-518, -518, -463, -353, -122, 44],
                      x: [-30, -30, -30, -30, 0, 0],
                    }}
                    transition={{
                      duration: 8,
                      ease: "easeInOut",
                      times: [0, 0.2, 0.4, 0.6, 0.8, 1], // Corresponding to 20%, 40%, etc.
                      delay: 0, // No delay for the first image
                      // repeat: Infinity, // Infinite loop
                      repeatDelay: 10,
                    }}
                  />
                  <motion.img
                    src="/images/dashboard/block.png"
                    alt=""
                    className="absolute z-10"
                    initial={{ top: 0, left: "-518px", x: -30 }}
                    animate={{
                      top: [-518, 0, 0, -116, -116, 55],
                      left: [-518, -518, -463, -353, -122, 10],
                      x: [-30, -30, -30, -30, 0, 0],
                    }}
                    transition={{
                      duration: 8,
                      ease: "easeInOut",
                      times: [0, 0.2, 0.4, 0.6, 0.8, 1], // Corresponding to 20%, 40%, etc.
                      delay: 2, // Delay of 1 second
                      // repeat: Infinity,
                      repeatDelay: 8,
                    }}
                  />
                  <motion.img
                    src="/images/dashboard/block.png"
                    alt=""
                    className="absolute z-10"
                    initial={{ top: 0, left: "-518px", x: -30 }}
                    animate={{
                      top: [-518, 0, 0, -116, -116, 55],
                      left: [-518, -518, -463, -353, -122, 76],
                      x: [-30, -30, -30, -30, 0, 0],
                    }}
                    transition={{
                      duration: 8,
                      ease: "easeInOut",
                      times: [0, 0.2, 0.4, 0.6, 0.8, 1], // Corresponding to 20%, 40%, etc.
                      delay: 4, // Delay of 2 seconds
                      // repeat: Infinity,
                      repeatDelay: 6,
                    }}
                  />
                  <motion.img
                    src="/images/dashboard/block.png"
                    alt=""
                    className="absolute z-10"
                    initial={{ top: 0, left: "-518px", x: -30 }}
                    animate={{
                      top: [-518, 0, 0, -116, -116, 21],
                      left: [-518, -518, -463, -353, -122, 10],
                      x: [-30, -30, -30, -30, 0, 0],
                    }}
                    transition={{
                      duration: 8,
                      ease: "easeInOut",
                      times: [0, 0.2, 0.4, 0.6, 0.8, 1], // Corresponding to 20%, 40%, etc.
                      delay: 6, // Delay of 3 seconds
                      // repeat: Infinity,
                      repeatDelay: 4,
                    }}
                  />
                  <motion.img
                    src="/images/dashboard/block.png"
                    alt=""
                    className="absolute z-10"
                    initial={{ top: 0, left: "-518px", x: -30 }}
                    animate={{
                      top: [-518, 0, 0, -116, -116, 21],
                      left: [-518, -518, -463, -353, -122, 76],
                      x: [-30, -30, -30, -30, 0, 0],
                    }}
                    transition={{
                      duration: 8,
                      ease: "easeInOut",
                      times: [0, 0.2, 0.4, 0.6, 0.8, 1], // Corresponding to 20%, 40%, etc.
                      delay: 8, // Delay of 4 seconds
                      // repeat: Infinity,
                      repeatDelay: 2,
                    }}
                  />
                </div> */}
                  <CubeAnimation />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 border border-[#EE0000] overflow-hidden shadow-[rgba(0,0,0,0.35)_0px_5px_15px] p-2 bg-[#121212] min-h-[240px] rounded-md flex flex-col justify-between">
            <h4 className="text-white text-16 md:text-[28px] underline font-semibold capitalize">
              No. of validated blocks
            </h4>
            <div className="flex justify-between items-center mt-5">
              <motion.div
                className="w-2/3"
                initial={{ x: "-100%", y: "100%", opacity: 0 }}
                animate={{ x: 0, y: 0, opacity: 1 }}
                transition={{ duration: 1.5, ease: "easeOut" }}
              >
                <img
                  src="/images/dashboard/check.png"
                  alt=""
                  className="w-full max-w-[200px]"
                />
              </motion.div>

              <motion.div
                className="text-[#EE0000] text-[100px] font-semibold w-1/3 text-center"
                initial={{ x: "100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.5, ease: "easeOut", delay: 0.6 }}
              >
                1
              </motion.div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2 md:gap-5">
          <div className="col-span-1 border border-[#EE0000] overflow-hidden shadow-[rgba(0,0,0,0.35)_0px_5px_15px] p-2 bg-[#121212] min-h-[240px] rounded-md flex flex-col justify-between">
            <h4 className="text-white text-16 md:text-[22px] underline font-semibold capitalize">
              Lifetime Earned Coins
            </h4>
            <div className="flex justify-between items-center mt-5">
              <motion.div
                className="w-2/3"
                initial={{ x: "-100%", y: "100%", opacity: 0 }}
                animate={{ x: 0, y: 0, opacity: 1 }}
                transition={{ duration: 1.5, ease: "easeOut" }}
              >
                <img
                  src="/images/dashboard/chart.png"
                  alt=""
                  className="w-full"
                />
              </motion.div>
              <motion.div
                className="text-[#EE0000] text-[100px] font-semibold w-1/3 text-center"
                initial={{ x: "100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.5, ease: "easeOut", delay: 0.6 }}
              >
                1
              </motion.div>
            </div>
          </div>
          <div className="col-span-1 border border-[#EE0000] overflow-hidden shadow-[rgba(0,0,0,0.35)_0px_5px_15px] p-2 bg-[#121212] min-h-[240px] rounded-md flex flex-col justify-between">
            <h4 className="text-white text-16 md:text-[22px] underline font-semibold capitalize">
              Current Balance
            </h4>
            <div className="flex justify-between items-center mt-5">
              <motion.div
                className="text-[#EE0000] text-[30px] font-semibold w-1/2 text-center"
                initial={{ x: "-100%", y: "100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.5, ease: "easeOut", delay: 0.6 }}
              >
                1,23,000
              </motion.div>
              <div className="w-1/2 relative">
                <motion.img
                  src="/images/dashboard/own/graph.png"
                  alt=""
                  className="w-full"
                  initial={{ x: "200%", y: "100%", opacity: 0 }}
                  animate={{ x: 0, y: 0, opacity: 1 }}
                  transition={{ duration: 1.5, ease: "easeOut" }}
                  onAnimationComplete={() => setGraphAnimationComplete(true)}
                />
                {graphAnimationComplete && (
                  <motion.img
                    src="/images/dashboard/own/coin.png"
                    alt=""
                    className="absolute top-[45px] left-[-15px] w-[60px] lg:w-[77px]"
                    initial={{ x: "100%", opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ duration: 1.2, ease: "easeOut" }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-span-1 border border-[#EE0000] overflow-hidden shadow-[rgba(0,0,0,0.35)_0px_5px_15px] p-2 bg-[#121212] min-h-[240px] rounded-md flex flex-col justify-between">
            <h4 className="text-white text-16 md:text-[22px] underline font-semibold capitalize">
              Withdrawal Balance
            </h4>
            <div className="flex justify-between items-center mt-5  h-[200px] relative">
              <motion.div
                className="text-[#EE0000] text-[30px] font-semibold w-1/2 text-center"
                initial={{ x: "-100%", y: "100%", opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.5, ease: "easeOut", delay: 0.6 }}
              >
                4,43,000
              </motion.div>
              <div className="w-1/2 relative h-full">
                <motion.img
                  src="/images/dashboard/withdrawal/money-bag-3.png"
                  alt=""
                  className="absolute bottom-0"
                  initial={{ x: "200%", y: "100%", opacity: 0 }}
                  animate={{ x: 0, y: 0, opacity: 1 }}
                  transition={{ duration: 1.5, ease: "easeOut" }}
                  onAnimationComplete={() => setWithdrawAnimationComplete(true)}
                />
                {withrawAnimationComplete && (
                  <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={{
                      visible: {
                        transition: {
                          staggerChildren: 0.3, // Delay between animations
                        },
                      },
                    }}
                  >
                    <motion.img
                      src="/images/dashboard/withdrawal/1.png"
                      alt=""
                      className="absolute top-[29px] left-[65px]"
                      initial={{ y: "100%", opacity: 0, rotate: 45 }}
                      animate={{ y: 0, opacity: 1, rotate: 0 }}
                      transition={{ duration: 1.2, ease: "easeOut" }}
                    />
                    <motion.img
                      src="/images/dashboard/withdrawal/2.png"
                      alt=""
                      className="absolute top-[-7px] left-[65px]"
                      initial={{ y: "100%", opacity: 0, rotate: 45 }}
                      animate={{ y: 0, opacity: 1, rotate: 0 }}
                      transition={{ duration: 1.2, ease: "easeOut" }}
                    />
                    <motion.img
                      src="/images/dashboard/withdrawal/3.png"
                      alt=""
                      className="absolute top-[-7px] left-[-15px]"
                      initial={{ y: "100%", opacity: 0, rotate: 45 }}
                      animate={{ y: 0, opacity: 1, rotate: 0 }}
                      transition={{ duration: 1.2, ease: "easeOut" }}
                    />
                    <motion.img
                      src="/images/dashboard/withdrawal/4.png"
                      alt=""
                      className="absolute top-[36px] left-[10px]"
                      initial={{ y: "100%", opacity: 0, rotate: 45 }}
                      animate={{ y: 0, opacity: 1, rotate: 0 }}
                      transition={{ duration: 1.2, ease: "easeOut" }}
                    />
                    <motion.img
                      src="/images/dashboard/withdrawal/5.png"
                      alt=""
                      className="absolute top-[57px] left-[60px]"
                      initial={{ y: "100%", opacity: 0, rotate: 45 }}
                      animate={{ y: 0, opacity: 1, rotate: 0 }}
                      transition={{ duration: 1.2, ease: "easeOut" }}
                    />
                    <motion.img
                      src="/images/dashboard/withdrawal/6.png"
                      alt=""
                      className="absolute top-[65px] left-[100px]"
                      initial={{ y: "100%", opacity: 0, rotate: 45 }}
                      animate={{ y: 0, opacity: 1, rotate: 0 }}
                      transition={{ duration: 1.2, ease: "easeOut" }}
                    />
                    <motion.img
                      src="/images/dashboard/withdrawal/7.png"
                      alt=""
                      className="absolute top-[30px] left-[125px]"
                      initial={{ y: "100%", opacity: 0, rotate: 45 }}
                      animate={{ y: 0, opacity: 1, rotate: 0 }}
                      transition={{ duration: 1.2, ease: "easeOut" }}
                    />
                  </motion.div>
                )}

                <motion.img
                  src="/images/dashboard/withdrawal/money-bag-2.png"
                  alt=""
                  className="absolute bottom-0"
                  initial={{ x: "200%", y: "100%", opacity: 0 }}
                  animate={{ x: 0, y: 0, opacity: 1 }}
                  transition={{ duration: 1.5, ease: "easeOut" }}
                  onAnimationComplete={() => setWithdrawAnimationComplete(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default DashboardLanding;
