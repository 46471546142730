import { Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import "animate.css";
import { Fragment, useEffect } from "react";
import PrivateRoute from "./PrivateRoute";
import Cookies from "js-cookie";
import { dashboardSideBar, sideBarLinks } from './utils/constants';
import NotFound from "./pages/NotFound";
import DashboardLanding from "./pages/Dashboard/DashboardLanding";
import RegisterPage from "./pages/Register/RegisterPage";
import ForgotPassword from './pages/ForgotPassword';
import ReportTwo from './pages/Report/ReportTwo';
import Report from './pages/Report';
import ReportOne from './pages/Report/ReportOne';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from './redux/Slice/User/user';
import DefaultDashboard from './layout/DefaultDashboard';
import PublicRoutes from './layout/PublicRoutes';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const setAuth = async (e) => {
      try {
      } catch (error) {}
    };
    if (Cookies.get(`accessToken`) === 'true') {
      setAuth();
    }
  }, []);
  const { userData } = useSelector((state) => state?.user || {});
  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);
  const isVerify = userData && userData?.isVerified;
  // useEffect(() => {
  //   const cookeiData = Cookies.get(`accessToken`);
  //   if (cookeiData && isVerify) {
  //     navigate('/dashboard');
  //   } else if (cookeiData) {
  //     navigate('/dashboard');
  //   } else {
  //     navigate('/');
  //   }
  // }, []);
  return (
    <>
      <ToastContainer />
      <Routes>
        <Route
          index
          path="register"
          element={
            <PublicRoutes>
              {' '}
              <RegisterPage />
            </PublicRoutes>
          }
        ></Route>
        <Route
          path="login"
          element={
            <PublicRoutes>
              {' '}
              <Login />
            </PublicRoutes>
          }
        ></Route>
        <Route
          path="forgot-password"
          element={
            <PublicRoutes>
              {' '}
              <ForgotPassword />
            </PublicRoutes>
          }
        ></Route>
        <Route
          path="/"
          element={
            <PublicRoutes>
              <DefaultDashboard />
            </PublicRoutes>
          }
        >
          {sideBarLinks.map((item, index) => (
            <Route
              key={index}
              path={item.path}
              element={<Fragment>{item.component}</Fragment>}
            ></Route>
          ))}
        </Route>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route index path="dashboard" element={<DashboardLanding />}></Route>

          <Route path="/report/:id?" element={<Report />}></Route>
          <Route path="/report/:id" element={<ReportOne />}></Route>
          <Route path="/report/:id/:1231" element={<ReportTwo />}></Route>

          {dashboardSideBar.map(
            (item, index) =>
              item.title !== 'Dashboard' && (
                <Route
                  key={index}
                  path={item.path}
                  element={<Fragment>{item.component}</Fragment>}
                ></Route>
              ),
          )}

          <Route path="*" element={<NotFound />}></Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
