import React, { useState } from "react";
import { isYupError, parseYupError } from "../../utils/Yup";
import { loginValidation } from "../../utils/validation";
import { toast } from "react-toastify";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { postReq } from "../../utils/apiHandlers";
import Loader from '../../components/loaders/Loader';
import Cookies from 'js-cookie';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
const initialState = {
  dialCode: '',
  emailOrMobile: '',
  password: '',
};
const Login = () => {
  const navigate = useNavigate();
  const [eye, setEye] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);
  const [formError, setFormError] = useState(initialState);
  const [focusedInput, setFocusedInput] = useState(null);
  const handleFocus = (name) => {
    setFocusedInput(name);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: '',
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    try {
      setIsLoading(true);
      await loginValidation.validate(form, {
        abortEarly: false,
      });

      const res = await postReq('/auth/login', form);
      const { status, data } = res;
      if (status) {
        Cookies.set('accessToken', data.accessToken);
        navigate('/kyc');
        toast.success('Login Successfully');
      } else {
        toast.error(res?.error?.message);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className=" h-[100dvh] bg-[#0F0F0F]">
      <div className="flex justify-center items-center flex-col h-[calc(100dvh-80px)] relative">
        <div className="text-white px-2 lg:px-0 lg:pt-0 pt-3">
          <div className="pt-2 lg:pt-0 pb-3">
            <h4 className="text-white text-20 md:text-[40px] lg:text-[46px] text-center">
              Welcome to{' '}
              <span className="text-[#EE0000] text-20 md:text-[40px] lg:text-[46px]">
                MST Blockchain.
              </span>
              <br />
              Log in to Access Your Node Dashboard
            </h4>
          </div>
          <div className="relative z-[9999]">
            <form onSubmit={handleSubmit} className="">
              <div className="max-w-[500px] mx-auto">
                <div className="input_field mb-3">
                  <div className="input_field">
                    <label className="mb-2 block">Phone Number</label>
                    {/* <label className="mb-2 block">Mobile Number</label> */}
                    <PhoneInput
                      country={'in'}
                      value={'num'}
                      onFocus={() => handleFocus('emailOrMobile')}
                      onBlur={handleBlur}
                      onChange={(phone, country) => {
                        setForm({
                          ...form,
                          dialCode: '+' + country.dialCode,
                          emailOrMobile: '+' + phone,
                        });

                        setFormError({
                          ...formError,
                          emailOrMobile: '',
                        });
                      }}
                      dropdownClass="custom-dropdown"
                      inputClass="py-4 px-2"
                      inputStyle={{
                        background: '#786e6e4d',
                        width: '100%',
                        border: 'none',
                        color: 'white',
                        height: '46px',
                      }}
                      dropdownStyle={{
                        background: 'white',
                        color: 'black',
                      }}
                      buttonStyle={{
                        background: '#786e6e4d',
                        border: 'none',
                      }}
                      autocompleteSearch="off"
                      className={`bg-[#0F0F0F] text-black border border-[#BEBEBE] rounded-md text-14 ${
                        focusedInput === 'emailOrMobile'
                          ? '!border-[#EE0000]'
                          : 'border-[#BEBEBE]'
                      }`}
                    />

                    {formError.emailOrMobile && (
                      <div className="form-eror">{formError.emailOrMobile}</div>
                    )}
                  </div>
                </div>
                <div className="input_field relative">
                  <label className="mb-2 block">Password</label>
                  <div className="flex items-center">
                    <input
                      type={eye ? 'text' : 'password'}
                      autoComplete="off"
                      placeholder="Enter password"
                      name="password"
                      onChange={handleChange}
                      onFocus={() => handleFocus('password')}
                      onBlur={handleBlur}
                      className={`bg-[#786e6e4d] text-white border text-12 flex-1 pr-12 ${
                        focusedInput === 'password'
                          ? '!border-[#EE0000]'
                          : 'border-[#BEBEBE]'
                      }`}
                    />
                    <span
                      className="absolute right-4 top-[36%] transform -translate-y-1/2 cursor-pointer z-0 no-focus-ring"
                      onClick={() => setEye(!eye)}
                    >
                      {eye ? (
                        <AiFillEye className="text-2xl text-theme-text mt-8" />
                      ) : (
                        <AiFillEyeInvisible className="text-2xl text-theme-text mt-8" />
                      )}
                    </span>
                  </div>
                  <div
                    className={`flex items-center ${
                      formError.password ? 'justify-between' : 'justify-end'
                    }`}
                  >
                    {formError.password && (
                      <div className="form-eror mt-0">{formError.password}</div>
                    )}
                    <div className="mt-2">
                      <Link
                        to={'/forgot-password'}
                        className="mb-2 block text-[#EE0000]"
                      >
                        Forgotten password ?
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-span-2 flex justify-end mt-10">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="text-black py-2.5 px-8 bg-white rounded-md font-semibold border border-[#EE0000] min-w-[140px]"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="text-white rounded-md font-semibold py-2.5 px-8 bg-[#EE0000] border border-white ml-4 md:text-16 text-12 min-w-[140px]"
                  >
                    {isLoading ? <Loader /> : 'Login'}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="fixed right-[8px] lg:right-[0px] bottom-0">
            <div className="fixed left-0 bottom-0">
              <img src="/images/icon/shape.png" alt="shape" />
            </div>
            <div className="fixed right-0 bottom-0">
              <img src="/images/wave.png" alt="Loading" />
            </div>

            <div className="fixed right-[50px] bottom-[40px]">
              <img
                src="/images/outlit/bigbox.png"
                alt="Wave background"
                className="w-[85px] opacity-[0.2] lg:opacity-100"
              />
            </div>
            <div className="fixed right-[165px] bottom-[112px]">
              <img
                src="/images/outlit/smallbox.png"
                alt="Wave background"
                className="w-[50px] opacity-[0.2] lg:opacity-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
