import { Link, useNavigate } from 'react-router-dom';
import GrowthCalculaterCol from './GrowthCalculaterCol/GrowthCalculaterCol';
import { useEffect, useRef, useState } from 'react';
import { postAuthReq } from '../../utils/apiHandlers';
import PdfTable from './PdfTable/PdfTable';

const GrowthCalculater = () => {
  const scrollPage = useRef();
  const navigate = useNavigate();
  const [isScroll, setIsScroll] = useState(false);
  const [fractionVal, setFractionVal] = useState();
  const [form, setForm] = useState({
    fraction: 1,
  });
  const getFractionValue = async () => {
    const res = await postAuthReq(
      '/purchase/node-fraction-price',
      form.fraction,
    );
    const { status, data } = res;
    if (status) {
      setFractionVal(data?.currentNodeFractionPrice);
    }
  };
  useEffect(() => {
    getFractionValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleExplore = () => {
    if (!isScroll) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };
  useEffect(() => {
    if (isScroll) {
      scrollPage.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isScroll]);
  return (
    <div className="w-full">
      <div className="text-start md:text-center">
        <p className="text-white font-bold leading-[30px] md:leading-[51.3px] text-[24px] md:text-[32px] mt-4 md:mt-6 text-center">
          Powering{' '}
          <span className="text-[#EE0000] text-[24px] md:text-[32px]">
            {' '}
            MST Blockchain
          </span>{' '}
          Strengthened by its Nodes.
        </p>
        <p className="text-[#EAEAEA] text-14px md:text-18 font-semibold leading-7 mt-4 text-center">
          Node Owners are the Backbone of MST Blockchain. Get Your Node
          and Become a Vital Part of Our Network!
        </p>
        <p className="text-[#BBBBBB] text-12 md:text-[14px] font-semibold leading-7 mt-4 4 text-center">
          As the MST blockchain grows, the value of your token
          increases— proving you made the right  investment.
        </p>
        <hr className="border-t-[#535353] mt-4 mb-2" />
      </div>
      <div className="hidden md:flex flex-col md:flex-row justify-between items-center py-3 px-4 md:px-12 gap-4 md:gap-6">
        <div className="flex flex-col md:flex-row items-center gap-2 md:gap-6 text-center md:text-left">
          <Link className="text-white text-sm md:text-base font-semibold border-b-2 border-b-white">
            Current price of Node fraction
          </Link>
          <h3 className="text-white text-base md:text-lg font-semibold p-2 border-red">
            {fractionVal && fractionVal?.toFixed(2)} INR
          </h3>
        </div>

        <div className="flex flex-col md:flex-row items-center gap-2 md:gap-6 text-center md:text-left">
          {/* <Link className="text-white text-sm md:text-base font-semibold border-b-2 border-b-white"></Link> */}
          <h3 className="text-white text-base md:text-lg font-semibold p-2 pb-0 border-b-2 border-b-white">
            1 MSTC = 0.02 USD
          </h3>
        </div>
        <div className="flex flex-col md:flex-row items-center gap-2 md:gap-6 text-center md:text-left">
          {/* <Link className="text-white text-sm md:text-base font-semibold border-b-2 border-b-white"></Link> */}
          <h3 className="text-white text-base md:text-lg font-semibold p-2 pb-0 border-b-2 border-b-white">
            Current Market Price = 0.02$
          </h3>
        </div>
      </div>
      {/* ----Mobile view registration button */}
      <div className="w-[150px] mx-auto lg:hidden">
        <button
          type="button"
          className="mt-2 rounded-lg text-12 md:text-16 text-white font-semibold w-full bg-[#EE0000] p-3 px-6"
          onClick={() => navigate('/register')}
        >
          {'Register Now'}
        </button>
      </div>
      <hr className="border-t-[#535353] mt-4 mb-2 w-[90%] mx-auto" />
      <div className="w-full">
        <GrowthCalculaterCol
          fractionVal={fractionVal}
          setForm={setForm}
          form={form}
          handleExplore={handleExplore}
        />
      </div>
      {isScroll && (
        <div className="pt-16" ref={scrollPage}>
          <PdfTable />
        </div>
      )}
    </div>
  );
};

export default GrowthCalculater;
