import React from 'react';
import { reactIcons } from '../../utils/icons';
import Loader from '../../components/loaders/Loader';
const Aadharcard = ({
  form,
  formError,
  handleChange,
  handleSentOtp,
  otpLoading,
  optSent,
  generateCaptcha,
  isRotating,
  captchaImg,
  getToken,
  isVerify,
}) => {
  return (
    <>
      <div className="input_field mb-3">
        <label className="mb-1 block text-12 font-semibold">
          Enter Aadhaar Card No.
        </label>
        <div className="relative">
          <input
            type="text"
            placeholder="Enter Aadhaar Number"
            name="aadharNumber"
            autoComplete="off"
            value={form.aadharNumber}
            onChange={handleChange}
            className="bg-white min-h-[45px] max-h-[45px] text-black !outline-none rounded border text-14 selection:bg-blue-200 selection:text-black"
          />

          {optSent && (
            <button
              type="button"
              className="text-white rounded flex items-center justify-center gap-2 font-normal py-2.5 px-4 bg-gradient-red border border-white text-14 min-w-[120px] absolute right-0 bottom-0 top-0"
              onClick={handleSentOtp}
              disabled={isVerify && getToken}
            >
              {otpLoading ? (
                <Loader />
              ) : (
                <>
                  Resend OTP
                  <img
                    src="/images/icon/send.png"
                    alt="send"
                    className="w-[12px]"
                  />
                </>
              )}
            </button>
          )}
        </div>
        {formError.aadharNumber && (
          <div className="form-eror">{formError.aadharNumber}</div>
        )}
      </div>
      {!optSent && (
        <div className="input_field mb-3">
          <label className="mb-1 block text-12 font-semibold">
            Enter Your Captcha
          </label>
          <input
            type="text"
            placeholder="Enter Your Captcha"
            name="captcha"
            autoComplete="off"
            value={form.captcha}
            onChange={handleChange}
            className="bg-white min-h-[45px] max-h-[45px] h-10 text-black !outline-none rounded border text-14"
          />

          {formError.captcha && (
            <div className="form-eror">{formError.captcha}</div>
          )}
        </div>
      )}
      {optSent && (
        <div className="input_field mb-3">
          <label className="mb-1 block text-12 font-semibold">Enter OTP</label>
          <input
            type="text"
            placeholder="Enter Your OTP"
            name="otp"
            value={form.otp}
            onChange={handleChange}
            className="bg-white min-h-[40px] max-h-[40px] h-10 text-black !outline-none rounded border text-14"
          />
          {formError.otp && <div className="form-eror">{formError.otp}</div>}
        </div>
      )}

      {!optSent && (
        <div className="mt-4 flex items-center">
          <img className="w-[100px]" src={captchaImg} alt="Loading" />{' '}
          <span
            className={`text-[30px] cursor-pointer ml-2 ${
              isRotating ? 'rotate-animation' : ''
            }`}
            onClick={generateCaptcha}
          >
            {reactIcons.refresh}
          </span>
        </div>
      )}
    </>
  );
};
export default Aadharcard;
