import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthReq } from '../../../utils/apiHandlers';

export const fetchUserData = createAsyncThunk(
  'fetchUserData/fetchUserData',
  async () => {
    const response = await getAuthReq('/users/me');
    if (!response.status) {
      throw new Error('Failed to fetch User');
    }
    return response?.data;
  },
);

const initialState = {
  userData: {},
  loading: true,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.userData = action.payload;
        state.loading = false;
      })
      .addCase(fetchUserData.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });
  },
});

export default userSlice.reducer;
