import React, { useState } from "react";
import { isYupError, parseYupError } from "../../utils/Yup";
import {
  forgotPasswordValidation,
  resetPassword,
} from '../../utils/validation';
import { toast } from 'react-toastify';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { postReq } from '../../utils/apiHandlers';
import Loader from '../../components/loaders/Loader';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
const initialState = {
  dialCode: '',
  mobile: '',
  otp: '',
  password: '',
};
const ForgotPassword = () => {
  const navigate = useNavigate();
  const [eye, setEye] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState(initialState);

  const [formError, setFormError] = useState(initialState);
  const [focusedInput, setFocusedInput] = useState(null);
  const handleFocus = (name) => {
    setFocusedInput(name);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: '',
    });
  };
  const handleOtp = async (e) => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await forgotPasswordValidation.validate(form, {
        abortEarly: false,
      });
      const payLoad = {
        dialCode: form.dialCode,
        mobile: form.mobile,
      };
      const res = await postReq('/auth/forgot-password', payLoad);
      const { status } = res;
      if (status) {
        setIsOtpSent(true);
        toast.success('Otp sent successfully');
      } else {
        toast.error(res?.error?.message[0]);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleChangePassword = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await resetPassword.validate(form, {
        abortEarly: false,
      });

      const res = await postReq('/auth/reset-password', form);
      const { status } = res;
      if (status) {
        setIsOtpSent(true);
        navigate('/login');
        toast.success('Password change successfully');
      } else {
        toast.error(res?.error?.message);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className="h-[100dvh] bg-[#0F0F0F]">
      {/* <Navbar /> */}
      <div className="flex justify-center items-start lg:items-center flex-col h-[calc(100dvh-80px)]">
        <div className="text-white px-2 lg:px-0 lg:pt-0 pt-3">
          <div className="pt-2 lg:pt-0 pb-3">
            <h4 className="text-white text-20 md:text-[40px] lg:text-[46px] text-center">
              Forgot Your{' '}
              <span className="text-white text-20 md:text-[40px] lg:text-[46px]">
                Password?
              </span>
              <br />
              Don't Worry, We've Got You Covered!
            </h4>
          </div>
          <div className="relative z-[9999]">
            <form className="">
              <div className="max-w-[500px] mx-auto">
                <div className="input_field mb-3">
                  <div className="input_field">
                    <label className="mb-2 block">Phone Number</label>
                    {/* <label className="mb-2 block">Mobile Number</label> */}
                    <PhoneInput
                      country={'in'}
                      value={'num'}
                      onFocus={() => handleFocus('mobile')}
                      onBlur={handleBlur}
                      onChange={(phone, country) => {
                        setForm({
                          ...form,
                          dialCode: '+' + country.dialCode,
                          mobile: phone,
                        });

                        setFormError({
                          ...formError,
                          mobile: '',
                        });
                      }}
                      dropdownClass="custom-dropdown"
                      inputClass="py-4 px-2"
                      inputStyle={{
                        background: '#786e6e4d',
                        width: '100%',
                        border: 'none',
                        color: 'white',
                        height: '46px',
                      }}
                      dropdownStyle={{
                        background: 'white',
                        color: 'black',
                      }}
                      buttonStyle={{
                        background: '#786e6e4d',
                        border: 'none',
                      }}
                      className={`bg-[#0F0F0F] text-black border border-[#BEBEBE] rounded-md text-14 ${
                        focusedInput === 'mobile'
                          ? '!border-[#EE0000]'
                          : 'border-[#BEBEBE]'
                      }`}
                    />
                    {formError.mobile && (
                      <div className="form-eror">{formError.mobile}</div>
                    )}
                  </div>
                </div>
                {isOtpSent && (
                  <>
                    <div className="input_field mb-3">
                      <label className="mb-2 block">Otp</label>
                      <div className="flex items-center">
                        <input
                          type="text"
                          placeholder="Enter otp"
                          name="otp"
                          onFocus={() => handleFocus('otp')}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          className={`bg-[#786e6e4d] text-white border text-12 flex-1 pr-12 ${
                            focusedInput === 'otp'
                              ? '!border-[#EE0000]'
                              : 'border-[#BEBEBE]'
                          }`}
                        />
                      </div>
                    </div>

                    <div className="input_field relative">
                      <label className="mb-2 block">New Password</label>
                      <div className="flex items-center">
                        <input
                          type={eye ? 'text' : 'password'}
                          placeholder="Enter new password"
                          name="password"
                          onChange={handleChange}
                          onFocus={() => handleFocus('password')}
                          onBlur={handleBlur}
                          className={`bg-[#786e6e4d] text-white border text-12 flex-1 pr-12 ${
                            focusedInput === ''
                              ? '!border-[#EE0000]'
                              : 'border-[#BEBEBE]'
                          }`}
                        />
                        <span
                          className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer z-0"
                          onClick={() => setEye(!eye)}
                        >
                          {eye ? (
                            <AiFillEye className="text-2xl text-theme-text mt-8" />
                          ) : (
                            <AiFillEyeInvisible className="text-2xl text-theme-text mt-8" />
                          )}
                        </span>
                      </div>
                    </div>
                  </>
                )}

                <div className="col-span-2 flex justify-end mt-10">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="text-black py-2.5 px-8 bg-white rounded-md font-semibold border border-[#EE0000] min-w-[140px]"
                  >
                    Back
                  </button>
                  {!isOtpSent ? (
                    <button
                      type="button"
                      onClick={handleOtp}
                      className="text-white rounded-md font-semibold py-2.5 px-8 bg-[#EE0000] border border-white ml-4 md:text-16 text-12 min-w-[140px]"
                    >
                      {isLoading ? <Loader /> : 'Send OTP'}
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={handleChangePassword}
                      className="text-white rounded-md font-semibold py-2.5 px-8 bg-[#EE0000] border border-white ml-4 md:text-16 text-12 min-w-[140px]"
                    >
                      {isLoading ? <Loader /> : 'Change Password'}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>

          <div className="absolute left-0 bottom-0">
            <img src="/images/icon/shape.png" alt="shape" />
          </div>
          <div className="absolute right-0 bottom-0">
            <img src="/images/wave.png" alt="Loading" />
          </div>

          <div className="absolute right-[50px] bottom-[40px]">
            <img
              src="/images/outlit/bigbox.png"
              alt="Wave background"
              className="w-[85px]"
            />
          </div>
          <div className="absolute right-[165px] bottom-[112px]">
            <img
              src="/images/outlit/smallbox.png"
              alt="Wave background"
              className="w-[50px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
