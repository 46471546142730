import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
const CubeAnimation = () => {
  const [key, setKey] = useState(0); // Track key state to trigger re-render

  useEffect(() => {
    // Set an interval to reset the key every 10 seconds
    const interval = setInterval(() => {
      setKey((prevKey) => prevKey + 1); // Increment key to re-render the component
    }, 20000); // 20 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, []);
  return (
    <div className="relative w-[150px] h-[150px]" key={key}>
      <motion.img
        src="/images/dashboard/block.png"
        alt=""
        className="absolute z-20"
        initial={{ top: 0, left: "-518px", x: -30 }}
        animate={{
          top: [-518, 0, 0, -116, -116, 75],
          left: [-518, -518, -463, -353, -122, 44],
          x: [-30, -30, -30, -30, 0, 0],
        }}
        transition={{
          duration: 8,
          ease: "easeInOut",
          times: [0, 0.2, 0.4, 0.6, 0.8, 1], // Corresponding to 20%, 40%, etc.
          delay: 0, // No delay for the first image
          // repeat: Infinity, // Infinite loop
          repeatDelay: 10,
        }}
      />
      <motion.img
        src="/images/dashboard/block.png"
        alt=""
        className="absolute z-10"
        initial={{ top: 0, left: "-518px", x: -30 }}
        animate={{
          top: [-518, 0, 0, -116, -116, 55],
          left: [-518, -518, -463, -353, -122, 10],
          x: [-30, -30, -30, -30, 0, 0],
        }}
        transition={{
          duration: 8,
          ease: "easeInOut",
          times: [0, 0.2, 0.4, 0.6, 0.8, 1], // Corresponding to 20%, 40%, etc.
          delay: 2, // Delay of 1 second
          // repeat: Infinity,
          repeatDelay: 8,
        }}
      />
      <motion.img
        src="/images/dashboard/block.png"
        alt=""
        className="absolute z-10"
        initial={{ top: 0, left: "-518px", x: -30 }}
        animate={{
          top: [-518, 0, 0, -116, -116, 55],
          left: [-518, -518, -463, -353, -122, 76],
          x: [-30, -30, -30, -30, 0, 0],
        }}
        transition={{
          duration: 8,
          ease: "easeInOut",
          times: [0, 0.2, 0.4, 0.6, 0.8, 1], // Corresponding to 20%, 40%, etc.
          delay: 4, // Delay of 2 seconds
          // repeat: Infinity,
          repeatDelay: 6,
        }}
      />
      <motion.img
        src="/images/dashboard/block.png"
        alt=""
        className="absolute z-10"
        initial={{ top: 0, left: "-518px", x: -30 }}
        animate={{
          top: [-518, 0, 0, -116, -116, 21],
          left: [-518, -518, -463, -353, -122, 10],
          x: [-30, -30, -30, -30, 0, 0],
        }}
        transition={{
          duration: 8,
          ease: "easeInOut",
          times: [0, 0.2, 0.4, 0.6, 0.8, 1], // Corresponding to 20%, 40%, etc.
          delay: 6, // Delay of 3 seconds
          // repeat: Infinity,
          repeatDelay: 4,
        }}
      />
      <motion.img
        src="/images/dashboard/block.png"
        alt=""
        className="absolute z-10"
        initial={{ top: 0, left: "-518px", x: -30 }}
        animate={{
          top: [-518, 0, 0, -116, -116, 21],
          left: [-518, -518, -463, -353, -122, 76],
          x: [-30, -30, -30, -30, 0, 0],
        }}
        transition={{
          duration: 8,
          ease: "easeInOut",
          times: [0, 0.2, 0.4, 0.6, 0.8, 1], // Corresponding to 20%, 40%, etc.
          delay: 8, // Delay of 4 seconds
          // repeat: Infinity,
          repeatDelay: 2,
        }}
      />
    </div>
  );
};

export default CubeAnimation;
