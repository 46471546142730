import React from "react";

// import DateRangePickerCustom from "../../components/Forms/DateRangePickerCustom";
// import moment from "moment";
import { reactIcons } from "../../utils/icons";
import { useNavigate } from "react-router-dom";
const ReportTwo = () => {
  const navigate = useNavigate();
  // const [startDate, setStartDate] = useState(
  //   moment().startOf("month").toDate()
  // );
  // const [endDate, setEndDate] = useState(moment().endOf("month").toDate());
  // const onChange = (dates) => {
  //   const [start, end] = dates;
  //   setStartDate(start);
  //   setEndDate(end);
  // };
  const headings = ['Time', 'Index', 'Receive Reward'];
  return (
    <div className="h-[100%]">
      <div className="flex items-center justify-between mb-3 md:mb-10">
        <h4 className="text-white text-start md:text-center text-16 md:text-[24px] lg:text-[24px] flex items-center gap-2">
          <button
            className="btn-gradient w-12 h-12 border border-white rounded-full flex items-center justify-center text-18"
            onClick={() => navigate(-1)}
          >
            {reactIcons.leftArrow}
          </button>
          Report
        </h4>
      </div>
      {/* <div className="flex flex-col lg:flex-row lg:items-center gap-2 justify-end w-full xl:w-auto mb-10">
        <p className="text-white text-16">Date</p>
        <div className="w-full lg:w-[273px] border border-[#EE0000] rounded-lg">
          <DateRangePickerCustom
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div> */}
      <div className="overflow-x-auto custom-scroll">
        <table className="text-xs leading-6 font-normal min-w-[1290px] w-full">
          <thead>
            <tr className="bg-gradient-red rounded-lg text-white">
              {headings.map((item, index) => {
                return (
                  <th key={item} className="group w-[300px]">
                    <div
                      className={`py-1 my-1 flex items-center justify-center text-left font-semibold ${
                        index === headings.length - 1
                          ? 'border-r-0'
                          : 'border-white border-r'
                      }`}
                    >
                      {item}
                    </div>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {Array(5)
              .fill(null)
              .map((item, index) => {
                return (
                  <tr
                    className=" bg-[#181B1D] h-[42px] border-2 border-[#393939] text-white mb-2"
                    key={index}
                  >
                    <td>
                      <div className="text-center border-r border-white">
                        12:20
                      </div>
                    </td>
                    <td>
                      <div className="text-center border-r border-white">2</div>{' '}
                    </td>
                    <td>
                      <div className="text-center">2.4</div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default ReportTwo;
