import React, { useEffect, useState } from 'react';
import AddressModal from '../../components/modals/AddressModal';
import { patchReq, postAuthReq } from '../../utils/apiHandlers';
import { isYupError, parseYupError } from '../../utils/Yup';
import { nodesPurchaseValidation, walletAddress } from '../../utils/validation';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../../redux/Slice/User/user';
import Loader from '../../components/loaders/Loader';
import ComingSoon from '../../components/Forms/ComingSoon';
const PurchaseNodes = () => {
  const dispatch = useDispatch();
  const [focusedInput, setFocusedInput] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [currentFractionPrice, setCurrentFractionPrice] = useState(0);
  const [fraction, setFraction] = useState();
  const [form, setForm] = useState({
    numberOfFractionToBuy: '',
    nodePrice: '',
    totalPriceOfEnteredFraction: '',
    youWillPay: '',
    walletAddress: '',
  });

  const [formError, setFormError] = useState({
    walletAddress: '',
    numberOfFractionToBuy: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
    setFormError({
      ...formError,
      [name]: '',
    });
  };

  const getCurrentFractionPrice = async () => {
    const res = await postAuthReq(
      '/purchase/node-fraction-price',
      form.fraction,
    );
    const { status, data } = res;
    if (status) {
      setFraction(data);
      setCurrentFractionPrice(data?.currentNodeFractionPrice);
    }
  };

  useEffect(() => {
    getCurrentFractionPrice();
  }, []);
  const totalPriceOfFraction =
    currentFractionPrice && currentFractionPrice * form.numberOfFractionToBuy;

  const addWalletAddress = async () => {
    if (isLoading) return;
    try {
      setLoading(true);
      await walletAddress.validate(form, {
        abortEarly: false,
      });
      let payload = {
        walletAddress: form.walletAddress,
      };
      const res = await patchReq('/users/me', payload);
      const { status, data } = res;
      if (status) {
        toast.success(data.status);
        setOpen(false);
        dispatch(fetchUserData());
      } else {
        toast.error(res?.error?.message[0]);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleBuyNodes = async () => {
    try {
      setLoading(true);
      if (isLoading) return;
      const { walletAddress, ...rest } = form;
      const payload = {
        ...rest,
        nodePrice: fraction.currentNodeFractionPrice,
        totalPriceOfEnteredFraction: totalPriceOfFraction,
        youWillPay: totalPriceOfFraction,
      };
      await nodesPurchaseValidation.validate(payload, {
        abortEarly: false,
      });
      const res = await postAuthReq('/purchase/payment-request', payload);
      const { status, data } = res;
      if (status) {
        //toast.success(data.message);
        toast.success('Purchased Successfully');
        setForm({
          numberOfFractionToBuy: '',
          nodePrice: '',
          totalPriceOfEnteredFraction: '',
          youWillPay: '',
          walletAddress: '',
        });
      } else {
        toast.error(res?.error.message);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    } finally {
      setLoading(false);
    }
  };

  const handleConnecWallet = async () => {
    try {
      await nodesPurchaseValidation.validate(form, {
        abortEarly: false,
      });
      setOpen(true);
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    }
  };

  const { userData } = useSelector((state) => state?.user || {});
  useEffect(() => {
    dispatch(fetchUserData());
  }, [dispatch]);
  const handleFocus = (name) => {
    setFocusedInput(name);
  };

  const handleBlur = () => {
    setFocusedInput(null);
  };
  return (
    <>
      <ComingSoon />
      <div className="flex justify-center items-start lg:items-center h-[100%]">
        <div className="text-white px-3">
          <div className="pb-3">
            <h4 className="text-white text-start md:text-center text-16 md:text-[28px] lg:text-[36px]">
              Secures Your Spot on the{' '}
              <span className="text-[#EE0000] text-16 md:text-[28px] lg:text-[36px]">
                MST Blockchain{' '}
              </span>
              <br />
              Purchase Your Nodes Today!
            </h4>
          </div>
          <div className="w-full max-w-[670px] mx-auto relative z-[9999]">
            <form className="">
              <div className="w-full gap-6 mt-4 ">
                <div className="mt-3 md:mt-2 lg:mt-3 border border-[#BEBEBE] p-[12px] rounded-md">
                  <div className="flex justify-between items-center">
                    <span className="text-[13px]">Current Node Price</span>
                    <div>
                      <span className="text-16 md:text-18 lg:text-20 font-semibold">
                        {currentFractionPrice &&
                          currentFractionPrice?.toFixed(2)}
                      </span>
                      <span className="text-white ml-4 font-semibold text-20">
                        INR
                      </span>
                    </div>
                  </div>
                </div>
                <div className="input_field mt-3 md:mt-2 lg:mt-3">
                  <label className="mb-2 block text-10 md:text-14">
                    Enter the number of fraction to buy
                  </label>
                  <input
                    type="number"
                    placeholder="0"
                    name="numberOfFractionToBuy"
                    onBlur={handleBlur}
                    onFocus={() => handleFocus('numberOfFractionToBuy')}
                    onChange={handleChange}
                    min={1}
                    className={`bg-[#0F0F0F] border text-14 ${
                      focusedInput === 'numberOfFractionToBuy'
                        ? '!border-[#EE0000]'
                        : 'border-[#BEBEBE]'
                    }`}
                  />
                  {formError.numberOfFractionToBuy && (
                    <div className="form-eror">
                      {formError.numberOfFractionToBuy}
                    </div>
                  )}
                </div>

                <div className="mt-2">
                  <label className="mb-2 block text-10 md:text-14">
                    Total price of entered fraction
                  </label>
                  <div className="mt-3 md:mt-2 lg:mt-3 border border-[#BEBEBE] p-[12px] rounded-md">
                    <div className="flex justify-between items-center">
                      <div>
                        <span className="text-16 md:text-18 lg:text-20 font-semibold">
                          {totalPriceOfFraction &&
                            totalPriceOfFraction.toLocaleString()}
                        </span>
                        <span className="text-white ml-4 font-semibold text-20">
                          INR
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <label className="mb-2 block text-10 md:text-14">
                    You will pay
                  </label>
                  <div className="mt-3 md:mt-2 lg:mt-3 border border-[#BEBEBE] p-[12px] rounded-md">
                    <div className="flex justify-between items-center">
                      <div>
                        <span className="text-16 md:text-18 lg:text-20 font-semibold">
                          {totalPriceOfFraction &&
                            totalPriceOfFraction.toLocaleString()}
                        </span>
                        <span className="text-white ml-4 font-semibold text-20">
                          INR
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="input_field mt-3 md:mt-2 lg:mt-3">
                <label className="mb-2 block text-10 md:text-14">
                  Total price of entered fraction
                </label>
                <div className="relative">
                  <span className="absolute left-2 text-white">INR</span>
                  <input
                    type="number"
                    placeholder="Enter Email"
                    name="totalPriceOfEnteredFraction"
                    value={totalPriceOfFraction}
                    onChange={handleChange}
                    className="bg-[#0F0F0F] border border-[#BEBEBE] text-16 md:text-18 lg:text-20 font-semibold"
                  />
                </div>
              </div>
              <div className="input_field mt-3 md:mt-2 lg:mt-3">
                <label className="mb-2 block text-10 md:text-14">
                  You will pay
                </label>
                <input
                  type="number"
                  placeholder="Enter Mobile Number"
                  name="youWillPay"
                  value={totalPriceOfFraction}
                  onChange={handleChange}
                  className="bg-[#0F0F0F] border border-[#BEBEBE] text-16 md:text-18 lg:text-20 font-semibold"
                />
              </div> */}
                <div>
                  <p className="text-[#FFFFFF] text-10 md:text-14 lg:text-16 mt-2">
                    Please connect your wallet address in which you receive all
                    your rewards before payment.{' '}
                  </p>
                </div>

                <div className="col-span-2 flex justify-end mt-8">
                  <button
                    type="button"
                    className="mr-3 text-black py-0 md:py-3 px-4 bg-white rounded-md font-semibold border border-[#EE0000] md:text-16 text-[8px]"
                  >
                    Cancel
                  </button>
                  {userData && !userData?.meta?.walletAddress && (
                    <button
                      type="button"
                      onClick={handleConnecWallet}
                      className="text-white rounded-md font-semibold py-3 px-6 bg-[#EE0000] ml-4 md:text-16 text-[8px]"
                    >
                      Connect Wallet
                    </button>
                  )}
                  {userData && userData?.meta?.walletAddress && (
                    <button
                      type="button"
                      onClick={handleBuyNodes}
                      className="text-white rounded-md font-semibold py-3 px-6 bg-[#EE0000] ml-4 md:text-16 text-12"
                    >
                      {isLoading ? <Loader /> : 'Pay Now'}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>

          <AddressModal
            setOpen={setOpen}
            open={open}
            isLoading={isLoading}
            formError={formError}
            handleChange={handleChange}
            addWalletAddress={addWalletAddress}
          />
        </div>
      </div>
    </>
  );
};
export default PurchaseNodes;
