import { Dialog, DialogContent } from "@mui/material";
import React from "react";
import { reactIcons } from "../../utils/icons";
import { useNavigate } from "react-router-dom";

// const initialState = {
//   upi: '',
//   image: '',
// };
const VerifyModal = ({ open, setOpen }) => {
  const navigate = useNavigate();
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // try {
  //   //   setFormError({});
  //   //   await QrCodeValidation.validate(form, {
  //   //     abortEarly: false,
  //   //   });
  //   //   const res = await api.addQrCode(form);
  //   //   if (res.data.status === 'success') {
  //   //     setIsLoading(false);
  //   //     toast.success('Added Successfully');
  //   //     _setImage('');
  //   //     handleCloseModal();
  //   //   } else {
  //   //     setIsLoading(false);
  //   //   }
  //   // } catch (error) {
  //   //   if (isYupError(error)) {
  //   //     setFormError(parseYupError(error));
  //   //   } else {
  //   //     toast.error(error.data.message);
  //   //   }

  //   //   setIsLoading(false);
  //   // }
  // };

  return (
    <>
      <Dialog
        open={open}
        // onClose={() => setOpen(false)}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'sm'}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent dividers={true}>
          <img
            src="/images/user.png"
            alt="user"
            className="w-20 h-20 rounded-full mx-auto"
          />
          <button
            className="bg-[#EE0000] p-2 absolute rounded text-white top-2 right-2"
            onClick={() => setOpen(false)}
          >
            {reactIcons.close}
          </button>
          <h3 className="text-center my-4">Verify your account</h3>
          <p className="text-black leading-[22px]">
            Complete your KYC to enjoy seamless and secure transactions. Ensure
            compliance by submitting valid identification documents through our
            user-friendly portal, making your financial experience hassle-free.
          </p>
          <button
            onClick={() => {
              navigate('/kyc');
              setOpen(false);
            }}
            type="button"
            className="btn btn-danger w-full mt-4 bg-[#EE0000]"
          >
            Complete Verification
          </button>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VerifyModal;
