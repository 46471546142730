import React from 'react';
const PanCard = ({ form, formError, handleChange }) => {
  return (
    <>
      <div className="input_field mb-3">
        <label className="mb-1 block text-12 font-semibold">
          Enter PAN Card No.
        </label>
        <div className="relative">
          <input
            type="text"
            placeholder="Enter PAN Card Number"
            autoComplete="off"
            name="panNumber"
            value={form?.panNumber?.toUpperCase()}
            onChange={handleChange}
            className="bg-white min-h-[45px] max-h-[45px] text-black !outline-none rounded border text-14 selection:bg-blue-200 selection:text-black"
          />
        </div>
        {formError.panNumber && (
          <div className="form-eror">{formError.panNumber}</div>
        )}
      </div>
    </>
  );
};
export default PanCard;
