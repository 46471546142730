import React from 'react';

const ComingSoon = () => {
  return (
    <div
      className={`p-10 text-center flex flex-col justify-center gap-10 pl-[253px] coming-soon-bg`}
    >
      <h1 className="text-[#EE0000] text-[37px] md:text-[80px]">Coming Soon</h1>
      <p className="text-white max-w-full md:max-w-[80%] mx-auto leading-[30px] text-[16px]">
        We’d like to inform you that Node Fraction Selling is coming soon!
        Currently, the blockchain mainnet is in beta. Once the public launch
        occurs, you will be prioritized as a validator, and we will notify you
        as soon as sales begin. Thank you for your patience!
      </p>
    </div>
  );
};

export default ComingSoon;
