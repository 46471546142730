import React from 'react';
import tableBodyData from '../../../helpers/pdfjson.json';
const tableHeading = [
  'Year',
  'Block Reward (Tokens)',
  'Annual Reward Tokens',
  'Cumulative Reward (Tokens)',
  'Node Price (INR)',
  'Total Value of Rewards',
  'Total Value in INR',
  'Profit from Investment (INR)',
  'Required Coin Stack',
  'Withdrawable Coins',
  'Reward in USD',
  'Reward in INR',
];
const head = ['Total Supply', 'Price', 'Valuation', 'Token price in INR'];
const bodyData = ['50,000,000,000', 'USD 0.02', '1,000,000,000.00', '₹ 1.66'];
const PdfTable = () => {
  return (
    <>
      <div className="w-full md:max-w-[700px] overflow-x-auto scrollbar-hidden">
        <table className="lg:w-full md:w-full w-[500px] overflow-x-auto rounded-md">
          <thead className="bg-gradient-1">
            <tr>
              {head.map((e, index) => (
                <th
                  key={index}
                  className="text-white font-semibold text-sm md:text-14 px-3 py-2 text-left"
                >
                  {e}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="bg-[#000000]">
            <tr>
              {bodyData.map((e, index) => (
                <td
                  key={index}
                  className="text-white text-sm md:text-14 font-semibold px-3 py-2"
                >
                  {e}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>

      <div className="overflow-x-auto bg-gradient-1 text-white px-5 py-3 mt-3 rounded-lg">
        <div className="min-w-[2000px]">
          <table class="w-full table-auto">
            <thead>
              <tr className="">
                {tableHeading.map((e) => (
                  <th className="text-[13px] text-center border-b border-b-white pb-2">
                    {e}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableBodyData &&
                tableBodyData?.data?.map((e, idx) => (
                  <tr key={idx} className="border-b border-b-red-500">
                    <td className="text-12 text-center border-b border-b-white pb-2">
                      {e.year}
                    </td>
                    <td className="text-12 text-center border-b border-b-white pb-2">
                      {e.blockRewardTokens}
                    </td>
                    <td className="text-12 text-center border-b border-b-white pb-2">
                      {e.annualRewardTokens.toLocaleString()}
                    </td>
                    <td className="text-12 text-center border-b border-b-white pb-2">
                      {e.cumulativeRewardTokens.toLocaleString()}
                    </td>
                    <td className="text-12 text-center border-b border-b-white pb-2">
                      {e?.nodePriceINR &&
                        '₹ ' + e?.nodePriceINR?.toLocaleString()}
                    </td>
                    <td className="text-12 text-center border-b border-b-white pb-2">
                      {e.totalValueOfRewardsUSD &&
                        'USD ' + e?.totalValueOfRewardsUSD?.toLocaleString()}
                    </td>
                    <td className="text-12 text-center border-b border-b-white pb-2">
                      {e.totalValueINR &&
                        '₹ ' + e?.totalValueINR.toLocaleString()}
                    </td>
                    <td className="text-12 text-center border-b border-b-white pb-2">
                      {e.profitFromInvestmentINR &&
                        '₹ ' + e.profitFromInvestmentINR.toLocaleString()}
                    </td>
                    <td className="text-12 text-center border-b border-b-white pb-2">
                      {e?.requiredCoinStack.toLocaleString()}
                    </td>
                    <td className="text-12 text-center border-b border-b-white pb-2">
                      {e?.withdrawableCoins.toLocaleString()}
                    </td>
                    <td className="text-12 text-center border-b border-b-white pb-2">
                      {e.rewardInUSD &&
                        'USD ' + e?.rewardInUSD.toLocaleString()}
                    </td>
                    <td className="text-12 text-center border-b border-b-white pb-2">
                      {e.rewardInINR && '₹ ' + e?.rewardInINR.toLocaleString()}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div className="note py-3">
            <p className="text-white text-12 md:text-14">
              <strong className="font-semibold">
                NOTE: Figures shown in the chart represents the status when
                Blockchain is fully up with all 21 Nodes.
              </strong>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default PdfTable;
