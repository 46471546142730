import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="flex flex-col items-center justify-center h-full">
      <h4 className="text-center text-white">Page Not Found</h4>

      <button
        className="btn btn-primary mt-2 bg-[#EE0000] border-white"
        onClick={() => navigate(-1)}
      >
        Go Back
      </button>
    </div>
  );
};

export default NotFound;
