import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { dashboardSideBar, sideBarLinks } from '../utils/constants';
import { reactIcons } from './../utils/icons';
import { toast } from 'react-toastify';
import { postAuthReq } from '../utils/apiHandlers';
import Cookies from 'js-cookie';
import Loader from './loaders/Loader';

const Sidebar = ({ isOpen, setIsOpen, isVerify, getToken }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const handleLogOut = async () => {
    if (isLoading) return;

    try {
      setIsLoading(true);

      const res = await postAuthReq('/auth/logout');
      const { data } = res;
      if (data.status === 'success') {
        toast.success('Logged Out Successfully');
        Object.keys(Cookies.get()).forEach(function (cookie, attributes) {
          Cookies.remove(cookie, attributes);
        });
        navigate('/');
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-common h-full flex flex-col justify-between overflow-hidden w-full pt-4">
      <div
        className={`pr-1 pl-[17px] flex ${
          !isOpen
            ? 'justify-end transition-all duration-75'
            : 'justify-start transition-all duration-75'
        }`}
      >
        <button
          onClick={() => {
            if (!isOpen) setIsOpen(true);
            else setIsOpen(false);
          }}
          className="w-10 h-10 flex-center text-white bg-common rounded-full text-3xl hidden lg:flex"
        >
          {!isOpen ? reactIcons.arrowright : reactIcons.arrowleft}
        </button>
      </div>
      <div className="flex flex-col gap-1 mt-4 flex-1 pl-2">
        {dashboardSideBar.map((item, index) => {
          const isKYC = item.title === 'KYC';
          const isDisabled = isKYC ? !(getToken && isVerify) : !isVerify;
          return (
            <NavLink
              key={index}
              to={isDisabled ? '#' : item.path}
              end
              className={
                ({ isActive }) =>
                  `relative py-3 w-full px-4 flex gap-4 items-center cursor-pointer duration-100 rounded-md ml-2 ${
                    isActive && !isDisabled
                      ? 'bg-[#FFE3E3] text-[#000000]'
                      : isDisabled
                      ? 'text-white cursor-not-allowed hover:bg-[#FFE3E3] hover:text-[#000000]'
                      : 'text-white hover:bg-[#FFE3E3] hover:text-[#000000]'
                  } ${
                    isKYC && !isVerify
                      ? 'bg-[#FFE3E3] cursor-pointer text-black'
                      : ''
                  }` // Highlight KYC if not verified
              }
              style={isDisabled ? { pointerEvents: 'none' } : {}}
            >
              <span className="text-xl">{item.icon}</span>
              {!isOpen && (
                <span className="text-[14px] xl:text-[16px]">{item.title}</span>
              )}
            </NavLink>
          );
        })}
      </div>
      <div
        onClick={handleLogOut}
        className="relative py-3 w-full ml-1 font-semibold rounded-md px-4 text-white hover:bg-[#FFE3E3] hover:text-[#000000] flex gap-4 items-center cursor-pointer duration-100"
      >
        <span className="text-xl flex items-center gap-[10px]">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {reactIcons.logout}
              {!isOpen && (
                <span className="text-[14px] xl:text-[16px]">Logout </span>
              )}
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default Sidebar;
