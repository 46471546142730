import React, { useState } from "react";

import DateRangePickerCustom from "../../components/Forms/DateRangePickerCustom";
import moment from "moment";
import { reactIcons } from "../../utils/icons";
import { useNavigate } from "react-router-dom";
import ReportTwo from "./ReportTwo";
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import ComingSoon from "../../components/Forms/ComingSoon";
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "white", // Tooltip text color
    background:
      "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
    fontSize: "16px",
    padding: "16px",
  },
});
const Report = () => {
  const [reportView, setReportView] = useState(false);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(
    moment().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf("month").toDate());
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const headings = [
    "Date",
    "Node ID",
    "No. of Block Validate",
    "Accumulated Reward",
  ];
  const handleNavigate = (idx) => {
    navigate(`/report/${idx}`);
  };
  const toolTipTitle =
    "Lorem Lorem Ipsum is simply dummy text of the printing and typesetting industry.";
  return (
    <>
      <ComingSoon />
      <div className="h-[100%]">
        <div className="flex items-center justify-between mb-3 md:mb-10">
          <h4 className="text-white text-start md:text-center text-16 md:text-[24px] lg:text-[24px]">
            Report
          </h4>
        </div>
        <div className="flex flex-col lg:flex-row lg:items-center gap-2 justify-end w-full xl:w-auto mb-10">
          <p className="text-white text-16">Date</p>
          <div className="w-full lg:w-[273px] border border-[#EE0000] rounded-lg">
            <DateRangePickerCustom
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
        <div className="overflow-x-auto custom-scroll opacity-[100%] duration-1000">
          <table className="text-xs leading-6 font-normal min-w-[1290px] w-full">
            <thead>
              <tr className="bg-gradient-red rounded-lg text-white">
                {headings.map((item, index) => {
                  return (
                    <th key={item} className="group">
                      <div
                        className={`py-1 my-1 flex items-center justify-center text-left font-semibold ${
                          index === headings.length - 1
                            ? "border-r-0"
                            : "border-white border-r"
                        }`}
                      >
                        {item}
                        {item === "Node ID" && (
                          <CustomTooltip title={toolTipTitle}>
                            <span
                              className="ml-3 cursor-pointer text-center text-14 font-semibold text-white 
  w-[23px] h-[23px] flex items-center justify-center 
  hover:bg-white hover:text-black hover:rounded-full transition-all duration-300"
                            >
                              !
                            </span>
                          </CustomTooltip>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {Array(6)
                .fill(null)
                .map((item, index) => {
                  return (
                    <tr
                      className=" bg-[#181B1D] h-[42px] border-2 border-[#393939] text-white mb-2"
                      key={index}
                    >
                      <td>
                        <div className="text-center border-r border-white">
                          04/10/2024
                        </div>
                      </td>
                      <td>
                        <div className="text-center border-r border-white">
                          {index + 1}
                        </div>
                      </td>
                      <td>
                        <div className="text-center border-r border-white">
                          10
                        </div>{" "}
                      </td>
                      <td>
                        <div className="grid grid-cols-3">
                          <div className="text-center border-r border-white col-span-2">
                            25000
                          </div>
                          <div className="flex items-center justify-center col-span-1">
                            <span onClick={() => handleNavigate(index)}>
                              {reactIcons.eyeVisible}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {reportView && <ReportTwo />}
        </div>
      </div>
    </>
  );
};
export default Report;
