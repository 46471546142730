import { Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import Loader from '../loaders/Loader';

// const initialState = {
//   upi: '',
//   image: '',
// };
const AddressModal = ({
  open,
  setOpen,
  handleChange,
  isLoading,
  formError,
  addWalletAddress,
}) => {
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   // try {
  //   //   setFormError({});
  //   //   await QrCodeValidation.validate(form, {
  //   //     abortEarly: false,
  //   //   });
  //   //   const res = await api.addQrCode(form);
  //   //   if (res.data.status === 'success') {
  //   //     setIsLoading(false);
  //   //     toast.success('Added Successfully');
  //   //     _setImage('');
  //   //     handleCloseModal();
  //   //   } else {
  //   //     setIsLoading(false);
  //   //   }
  //   // } catch (error) {
  //   //   if (isYupError(error)) {
  //   //     setFormError(parseYupError(error));
  //   //   } else {
  //   //     toast.error(error.data.message);
  //   //   }

  //   //   setIsLoading(false);
  //   // }
  // };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'sm'}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent dividers={true}>
          <div className="input_field mt-2">
            <label className="mb-2 block font-semibold text-black text-14px md:text-18">
              Enter the wallet address where you would like to receive your
              rewards.
            </label>
            <input
              type="text"
              placeholder="Enter Your Wallet Address (0x)...."
              name="walletAddress"
              onChange={handleChange}
              className="bg-[#fff] border border-[#EE0000]"
            />
            {formError.formError && (
              <div className="form-eror">{formError.formError}</div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <button
            onClick={addWalletAddress}
            // onClick={() => {
            //   setIsConnectWallet(true);
            //   setOpen(false);
            // }}
            type="button"
            className="btn btn-danger min-w-[130px] md:min-w-[200px] bg-[#EE0000]"
          >
            {isLoading ? <Loader /> : 'Submit'}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddressModal;
