import React from 'react';
import { reactIcons } from '../../utils/icons';
import { Dialog, DialogContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const KycCompleted = ({ open, setOpen }) => {
  const navigate = useNavigate();
  return (
    <div className="">
      <Dialog
        open={open}
        // onClose={() => setOpen(false)}
        scroll={'paper'}
        fullWidth={true}
        maxWidth={'sm'}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogContent dividers={true}>
          <div className="flex justify-center">
            <span className="text-[40px] md:text-[50px] lg:text-[60px] text-green-500 inline-block bg-gray-600 rounded-full p-3">
              {reactIcons.check}
            </span>
          </div>
          <button
            className="bg-[#EE0000] p-2 absolute rounded text-white top-2 right-2"
            onClick={() => navigate('/dashboard')}
          >
            {reactIcons.close}
          </button>
          {/* <h3 className="text-center my-4">Verify your account</h3> */}
          <p className="text-black leading-[22px] mt-5 text-12 md:text-14 lg:text-16">
            Thank you for becoming a member of mstvalidator.com and completing
            your KYC process. We want to inform you that Node Fraction Selling
            has not yet begun, as the blockchain mainnet is currently in beta.
            Once Blockchain launch for public, you will be prioritized as a
            validator, and we will notify you as soon as sales commence.
          </p>
          <button
            onClick={() => {
              navigate('/dashboard');
              setOpen(false);
            }}
            type="button"
            className="btn btn-danger w-full mt-6 bg-[#EE0000]"
          >
            Your KYC Verification has been done.
          </button>
        </DialogContent>
      </Dialog>
      {/* <div className="flex flex-col items-center justify-center bg-white py-3 w-[50%] mx-auto mt-20 rounded-md">
        <span className="block text-[80px] text-green-500">
          {reactIcons.check}
        </span>
        <h2 className="text-center text-color text-[50px] mt-4">
          Thank you for becoming a member of mstvalidator.com and completing
          your KYC process. We want to inform you that Node Fraction Selling has
          not yet begun, as the blockchain mainnet is currently in beta. Once
          Blockchain launch for public, you will be prioritized as a validator,
          and we will notify you as soon as sales commence.
        </h2>
      </div> */}
    </div>
  );
};
export default KycCompleted;
